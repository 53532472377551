// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.last-contained-group {
  display: grid;
  row-gap: 16px;
  -moz-column-gap: 16px;
       column-gap: 16px;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape) {
  .last-contained-group {
    row-gap: 5px;
    grid-template-columns: repeat(1, 1fr);
  }
}

.loader-container {
  z-index: 2;
  position: absolute;
}

.spinner-container {
  z-index: 2;
  margin-right: 30px;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/last-contained-group/last-contained-group.component.scss","webpack://./src/mixins.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,aAAA;EACA,qBAAA;OAAA,gBAAA;EACA,qCAAA;AADF;ACHI;EDAJ;IAOI,YAAA;IACA,qCAAA;EAAF;AACF;;AAGA;EACE,UAAA;EACA,kBAAA;AAAF;;AAGA;EACE,UAAA;EACA,kBAAA;EACA,kBAAA;AAAF","sourcesContent":["@import '../../../mixins.scss';\n\n.last-contained-group {\n  display: grid;\n  row-gap: 16px;\n  column-gap: 16px;\n  grid-template-columns: repeat(2, 1fr);\n\n  @include breakpoint(mobile) {\n    row-gap: 5px;\n    grid-template-columns: repeat(1, 1fr);\n  }\n}\n\n.loader-container {\n  z-index: 2;\n  position: absolute;\n}\n\n.spinner-container {\n  z-index: 2;\n  margin-right: 30px;\n  position: relative;\n}\n","@mixin breakpoint($point) {\n  @if $point == mobile {\n    @media (max-width: 599.98px) and (orientation: portrait),\n      (max-width: 959.98px) and (orientation: landscape) {\n      @content;\n    }\n  } @else if $point == tablet {\n    @media (min-width: 600px) and (max-width: 839.98px) and (orientation: portrait),\n      (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape) {\n      @content;\n    }\n  } @else if $point == desktop {\n    @media (min-width: 840px) and (orientation: portrait),\n      (min-width: 1280px) and (orientation: landscape) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
