import { Component, OnInit, Inject, Optional, OnDestroy } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';

@Component({
  selector: 'app-missed-need-by-time-report',
  templateUrl: './missed-need-by-time-report.component.html',
  styleUrls: ['./missed-need-by-time-report.component.scss'],
})
export class MissedNeedByTimeReportComponent implements OnInit, OnDestroy {
  missedNeedByTimeForm: UntypedFormGroup;
  reasonCode: UntypedFormControl;
  additionalComments: UntypedFormControl;
  showNotesDisclaimer: boolean;
  formStatusChangesSubscription: Subscription;
  actionsBarConfig: ActionsBarProperties;

  constructor(
    private dialogRef: MatDialogRef<MissedNeedByTimeReportComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public reasons: any
  ) {}

  ngOnDestroy(): void {
    this.formStatusChangesSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.createFormControls();
    this.createFormGroup();
    this.initActionsBarConfigs();
  }

  initActionsBarConfigs() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.onNoClick(false),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
        },
        {
          btnClick: () => this.submitForm(),
          btnId: 'save',
          btnText: 'Stop',
          icon: 'stop_circle',
          buttonStyle: ButtonStyle.PRIMARY,
          btnDisabled: !this.missedNeedByTimeForm.valid,
        },
      ],
    };
    this.formStatusChangesSubscription =
      this.missedNeedByTimeForm.statusChanges.subscribe((status) => {
        this.actionsBarConfig.buttons[1].btnDisabled = status !== 'VALID';
      });
  }

  isReasonValid(reason, comments) {
    if (reason === 'Other' && (comments === null || comments < 1)) {
      this.showNotesDisclaimer = true;
      return false;
    }
    this.showNotesDisclaimer = false;
    return true;
  }

  submitForm = () => {
    this.initActionsBarConfigs();
    if (
      !this.isReasonValid(
        this.missedNeedByTimeForm.get('reasonCode').value,
        this.missedNeedByTimeForm.get('additionalComments').value
      )
    ) {
      return;
    }
    if (this.missedNeedByTimeForm.valid) {
      this.showNotesDisclaimer = false;
      const foundReason = this.reasons.find(
        (reason) =>
          reason.displayName ===
          this.missedNeedByTimeForm.get('reasonCode').value
      );
      this.dialogRef.close({
        displayName: this.missedNeedByTimeForm.get('reasonCode').value,
        additionalComments:
          this.missedNeedByTimeForm.get('additionalComments').value,
        code: foundReason.code,
      });
    }
  };

  createFormGroup() {
    this.missedNeedByTimeForm = new UntypedFormGroup({
      additionalComments: this.additionalComments,
      reasonCode: this.reasonCode,
    });
  }

  createFormControls() {
    this.additionalComments = new UntypedFormControl();
    this.reasonCode = new UntypedFormControl('', Validators.required);
  }

  isOtherReason() {
    return this.reasonCode.value && this.reasonCode.value === 'Other';
  }

  onNoClick = (option) => {
    this.dialogRef.close(option);
  };

  onReasonClicked(reason: string) {
    this.missedNeedByTimeForm.get('reasonCode').setValue(reason);
  }
}
