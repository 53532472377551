// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.response-container {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding: 1rem;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-row-gap: 2rem;
}

.header {
  width: 100%;
  border-bottom: 2px solid #e6e6e6;
}

.logo {
  width: 7rem;
}

.body {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.response-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-weight: 800;
  text-align: center;
  font-size: 2rem;
}

.icon {
  font-size: 14rem;
}

.text {
  white-space: pre-wrap;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2;
}`, "",{"version":3,"sources":["webpack://./src/app/exterior-wash-offer-response/exterior-wash-offer-response.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EAEA,aAAA;EACA,cAAA;EAEA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AADF;;AAIA;EACE,WAAA;EACA,gCAAA;AADF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,WAAA;EACA,YAAA;EAEA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAFF;;AAKA;EACE,gBAAA;EACA,kBAAA;EACA,eAAA;AAFF;;AAKA;EACE,gBAAA;AAFF;;AAKA;EACE,qBAAA;EACA,kBAAA;EACA,iBAAA;EACA,gBAAA;EACA,cAAA;AAFF","sourcesContent":[".response-container {\n  width: 100%;\n  height: auto;\n  min-height: 100vh;\n\n  padding: 1rem;\n  margin: 0 auto;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  grid-row-gap: 2rem;\n}\n\n.header {\n  width: 100%;\n  border-bottom: 2px solid #e6e6e6;\n}\n\n.logo {\n  width: 7rem;\n}\n\n.body {\n  width: 100%;\n  flex-grow: 1;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.response-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.title {\n  font-weight: 800;\n  text-align: center;\n  font-size: 2rem;\n}\n\n.icon {\n  font-size: 14rem;\n}\n\n.text {\n  white-space: pre-wrap;\n  text-align: center;\n  font-size: 1.5rem;\n  font-weight: 500;\n  line-height: 2;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
