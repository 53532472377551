import { Component } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { MenuOption } from '../../shared/navigation/navigation.model';
import { FileItemService } from 'src/app/shared/file-upload/file-item/file-item.service';
@Component({
  selector: 'app-bulk-upload',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './bulk-upload.component.html',
  styleUrl: './bulk-upload.component.scss',
})
export class BulkUploadComponent {
  static route = `bulk-upload/${MenuOption.DownloadTemplate}`;
  isDownloading: boolean;

  constructor(private fileService: FileItemService) {}

  downloadFile() {
    this.isDownloading = true;
    this.fileService.downloadBulkTemplate();
    this.isDownloading = false;
  }
}
