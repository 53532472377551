import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ManualStartStopReasonFieldsEnum } from './manual-start-stop-reason-fields.enum';
import { Subscription } from 'rxjs';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';

@Component({
  selector: 'app-manual-start-stop-reason',
  templateUrl: './manual-start-stop-reason.component.html',
  styleUrls: ['./manual-start-stop-reason.component.scss'],
})
export class ManualStartStopReasonComponent implements OnInit, OnDestroy {
  formGroup: FormGroup;
  comments: FormControl;
  formFieldsEnum = ManualStartStopReasonFieldsEnum;
  formStatusChangesSubscription: Subscription;
  actionsBarConfig: ActionsBarProperties;

  constructor(
    public dialogRef: MatDialogRef<ManualStartStopReasonComponent>,
    private formBuilder: FormBuilder
  ) {
    this.createFormControls();
    this.createFormGroup();
    this.initActionsBarConfigs();
  }

  ngOnDestroy(): void {
    this.formStatusChangesSubscription.unsubscribe();
  }

  initActionsBarConfigs() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.cancel(),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
        },
        {
          btnClick: () => this.save(),
          btnId: 'save',
          btnText: 'Save',
          icon: 'save',
          buttonStyle: ButtonStyle.PRIMARY,
          btnDisabled: !this.formGroup.valid,
        },
      ],
    };
    this.formStatusChangesSubscription = this.formGroup.statusChanges.subscribe(
      (status) => {
        this.actionsBarConfig.buttons[1].btnDisabled = status !== 'VALID';
      }
    );
  }

  ngOnInit(): void {}

  save = () => {
    if (this.formGroup.valid) {
      this.dialogRef.close({
        additionalComments: this.comments.value,
        code: 'OTHER',
      });
    }
  };

  cancel = () => {
    this.dialogRef.close();
  };

  private createFormControls() {
    this.comments = new FormControl('', Validators.required);
  }

  private createFormGroup() {
    this.formGroup = this.formBuilder.group({
      comments: this.comments,
    });
  }
}
