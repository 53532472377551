import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DialogConfirmationComponent } from './dialog-confirmation.component';
import { BehaviorSubject } from 'rxjs';
import { DialogData } from './dialog-confirmation.model';

@Injectable({
  providedIn: 'root',
})
export class DialogConfirmationService {
  // tslint:disable-next-line: variable-name
  private _dialogResult = new BehaviorSubject(false);
  dialogResult = this._dialogResult.asObservable();

  constructor(private dialog: MatDialog) {}

  openDialog(data: DialogData) {
    const dialogRef = this.dialog.open(DialogConfirmationComponent, {
      minWidth: '25%',
      panelClass: 'custom-dialog',
      data,
    });

    return dialogRef
      .afterClosed()
      .toPromise()
      .then((r) => {
        return r;
      })
      .catch((err) => {
        return Promise.reject(err);
      });
  }
}
