import { Component } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuOption } from '../../../shared/navigation/navigation.model';

@Component({
  selector: 'app-rpa',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './rpa.component.html',
  styleUrl: './rpa.component.scss',
})
export class RpaComponent {
  static route = `other-tools/${MenuOption.OtherToolsRpa}`;
}
