import { Component, Input } from '@angular/core';
import { ActionsBarProperties } from '../actions-bar/actions-bar.model';

@Component({
  selector: 'app-pop-up',
  templateUrl: './pop-up.component.html',
  styleUrl: './pop-up.component.scss',
})
export class PopUpComponent {
  @Input() onNoClick: (status: boolean) => void;
  @Input() popUpTitle: string;
  @Input() centralizePopUpTitle: boolean;
  @Input() actionsBarConfig: ActionsBarProperties;
}
