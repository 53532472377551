import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-main-nav-mobile',
  standalone: true,
  imports: [RouterModule, MatMenuModule, FaIconComponent],
  templateUrl: './main-nav-mobile.component.html',
  styleUrl: './main-nav-mobile.component.scss',
})
export class MainNavMobileComponent {
  userName = this.authService.user?.name ?? '';

  constructor(private authService: AuthService) {}
}
