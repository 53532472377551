import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subscription } from 'rxjs';
import { ConfinedSpaceEntryDataUtils } from './confined-space-entry.data.utils';
import { MatOptionSelectionChange } from '@angular/material/core';
import { Operator } from 'src/app/dashboard/schedule/schedule.model';
import {
  ActionsBarProperties,
  ButtonStyle,
} from '../actions-bar/actions-bar.model';

@Component({
  selector: 'app-confined-space-entry',
  templateUrl: './confined-space-entry.component.html',
  styleUrls: ['./confined-space-entry.component.scss'],
})
export class ConfinedSpaceEntryComponent implements OnInit {
  formGroup: FormGroup;
  confinedEntry: FormControl;
  confinedEntryOperatorName: FormControl;
  confinedEntryType: FormControl;
  userTypedOperatorName: boolean = false;
  lastSelectedOperatorId: string;
  isDisabled: boolean = false;
  formFieldsEnum = {
    confinedEntry: 'Executed',
    confinedEntryType: 'Type',
    confinedEntryOperatorName: 'Work Performed By',
  };

  operators$: Observable<any>;
  uploadedFiles: Array<any> = [];
  operators: Array<Operator>;
  filteredOperators: any;
  workOrderStatus: string;
  formStatusChangesSubscription: Subscription;
  actionsBarConfig: ActionsBarProperties = {} as any;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ConfinedSpaceEntryComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.operators = this.data.operators;
    this.workOrderStatus = this.data.workOrderStatus;
  }

  ngOnInit(): void {
    this.createFormGroup();

    ConfinedSpaceEntryDataUtils.handleOperatorsInputList(this);
    ConfinedSpaceEntryDataUtils.handleConfinedEntryFormFields(this);
    ConfinedSpaceEntryDataUtils.handledConfinedEntryInitialData(this);
    this.handleWorkOrderCompleted();
    this.initActionsBarConfigs();
  }

  updateOperatorName(
    event: MatOptionSelectionChange,
    operatorName: string,
    operatorId: string
  ) {
    ConfinedSpaceEntryDataUtils.updateOperatorName(
      this,
      event,
      operatorName,
      operatorId
    );
  }

  private createFormGroup() {
    this.formGroup = this.formBuilder.group(
      ConfinedSpaceEntryDataUtils.createCseFormControls(this)
    );
  }

  initActionsBarConfigs() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.cancel(),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
        },
        {
          btnClick: () => this.save(),
          btnId: 'save',
          btnText: 'Save',
          icon: 'save',
          buttonStyle: ButtonStyle.PRIMARY,
          btnDisabled: !this.formGroup.valid,
        },
      ],
    };
    this.formStatusChangesSubscription = this.formGroup.statusChanges.subscribe(
      (status) => {
        this.actionsBarConfig.buttons[1].btnDisabled = status !== 'VALID';
      }
    );
  }

  cancel = () => {
    return this.dialogRef.close();
  };

  save = () => {
    const cseData = ConfinedSpaceEntryDataUtils.compileCseDataToSave(this);
    this.dialogRef.close(cseData);
  };

  isWorkOrderCompleted() {
    return this.workOrderStatus === 'Completed';
  }

  handleWorkOrderCompleted() {
    if (this.isWorkOrderCompleted()) {
      this.confinedEntryOperatorName.disable();
    }
  }
}
