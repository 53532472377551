import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NavigationService } from '../navigation.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { PermissionGuard } from 'src/app/core/auth/permission.guard';
import { MenuConfig, OptionConfig, SectionConfig } from '../navigation.model';
import { getSectionDict } from '../navigation.config';

@Component({
  selector: 'app-main-nav-desktop',
  templateUrl: './main-nav-desktop.component.html',
  styleUrl: './main-nav-desktop.component.scss',
})
export class MainNavDesktopComponent implements OnChanges {
  sectionExpanded: string;

  @Input() menuExpanded: boolean;
  @Input() menuConfig: MenuConfig;

  @Output() sectionSelected = new EventEmitter<string>();
  @Output() emitSubnavPermission = new EventEmitter<boolean>();
  @Output() menuSelected = new EventEmitter<boolean | string>();
  @Output() toggleSubnav = new EventEmitter<boolean | string>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private permissionGuard: PermissionGuard,
    private changeDetectorRef: ChangeDetectorRef,
    private navigationService: NavigationService
  ) {}

  ngOnChanges(): void {
    this.changeDetectorRef.detectChanges();
  }

  hasSubSystemAccess(menuOption: OptionConfig | SectionConfig) {
    const { user } = this.authService;

    const authorizedRoles =
      'authorizedRoles' in menuOption
        ? menuOption.authorizedRoles
        : menuOption?.options?.map((item) => item.authorizedRoles).flat() || [];

    const hasPermission = this.permissionGuard.isSectionAvailable(
      authorizedRoles,
      user.currentRole
    );

    if (!hasPermission) {
      this.router.navigate(['/dashboard/access-denied'], {
        queryParams: { from: `/dashboard/${menuOption.value}` },
      });

      this.emitSubnavPermission.emit(false);
    } else {
      this.emitSubnavPermission.emit(true);
      this.toggleSubnav.emit('expand-subnav');
    }
    this.emitSectionSelected(menuOption.value);
  }

  isSubsystemSelected(menuOption: OptionConfig): boolean {
    if (this.sectionExpanded) {
      if (this.sectionExpanded == menuOption?.value) return true;
      else return false;
    } else {
      const menuExpanded = this.navigationService.getMenuSectionThroughUrl(
        this.menuConfig
      );
      return menuExpanded?.value === menuOption?.value;
    }
  }

  logout() {
    this.router.navigate([`/logout`]);
  }

  subnavEmit() {
    this.toggleSubnav.emit();
  }

  emitSectionSelected(section: string) {
    this.sectionExpanded = section;
    this.sectionSelected.emit(section);
  }
}
