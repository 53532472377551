import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  WashRequest,
  WashRequestStatus,
  OrderConversionStatuses,
  ExteriorWashOfferStatuses,
  WashRequestFileFlag,
} from '../wash-list.model';
import { WashListService } from '../wash-list.service';
import { AuthService } from '../../../core/auth/auth.service';
import { ActivatedRoute } from '@angular/router';
import { WashItemService } from './wash-item.service';
import {
  ActionsControl,
  ErrorMessages,
  PopUpActionType,
  StandardServicePlanNames,
} from './wash-item.model';
import { CompartmentServiceType, BusinessPartner } from '../wash-list.model';
import { UntypedFormControl } from '@angular/forms';
import { switchMap, take } from 'rxjs/operators';
import { HeelActions } from './heel-actions';
import { ToastrService } from 'ngx-toastr';
import { DialogConfirmationService } from 'src/app/shared/dialog-confirmation/dialog-confirmation.service';
import { MatDialog } from '@angular/material/dialog';
import { WashRejectComponent } from '../wash-reject/wash-reject.component';
import { WashRejectWithHeelComponent } from '../wash-reject-with-heel/wash-reject-with-heel.component';
import { User } from 'src/app/core/model/user.model';
import { ExpandedImageComponent } from 'src/app/shared/expanded-image/expanded-image.component';
import { FileItemService } from 'src/app/shared/file-upload/file-item/file-item.service';
import { ServicePlanPopUpOverlayComponent } from '../shared/service-plan-pop-up-overlay/service-plan-pop-up-overlay.component';
import { ServicePlanPopUpInputModel } from '../shared/service-plan-pop-up-overlay/service-plan-pop-up.model';
import { popUpDisclaimers } from '../shared/service-plan-pop-up-overlay/service-plan-pop-up.constants';
import { BusinessPartnerService } from 'src/app/core/services/business-partner.service';
import { Observable } from 'rxjs';
import { Operator } from '../../schedule/schedule.model';
import { ConfinedSpaceEntryDialogService } from 'src/app/shared/confined-space-entry/confined-space-entry.dialog.service';
import { TimeService } from '../../../core/time.service';
import { OperatorsDialogService } from '../../schedule/operators-dialog/operators-dialog.service';
import { Location } from '@angular/common';
import { ManualStartStopReasonService } from '../../schedule/start-stop-confirmation/manual-start-stop-reason/manual-start-stop-reason.service';
import { StartStopConfirmationService } from '../../schedule/start-stop-confirmation/start-stop-confirmation.service';
import { LoadingDialogService } from 'src/app/shared/loading-dialog.service';
import { LocalStorageService } from 'src/app/core/localstorage.service';
import { MenuOption } from 'src/app/shared/navigation/navigation.model';
import {
  ActionsBarProperties,
  Button,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';
import { MainIconEtendoComponent } from 'src/app/shared/main-icon-etendo/main-icon-etendo.component';

@Component({
  selector: 'app-wash-item',
  templateUrl: './wash-item.component.html',
  styleUrls: ['./wash-item.component.scss'],
})
export class WashItemComponent implements OnInit, OnChanges {
  currentMenuOption: MenuOption;
  showDetail: boolean;
  hideActionBar: boolean;
  actionsControl: ActionsControl;
  hasQualaWorkerRole: boolean;
  isEditing = false;
  isRequestHeelApproval: boolean;
  isHeelManagement: boolean;
  heelSelectedAction: string;
  selectedServicePlanName: string;
  specialPrep: UntypedFormControl;
  businessPartner: BusinessPartner;
  heelDetails: any;
  revisionFields: any;
  dialogResult: boolean;
  isNotMobile: boolean;
  isProcessing: boolean;
  isRedServicePlan: boolean;
  showRecommendedServicePlan: boolean;
  showSelectedServicePlan: boolean;
  createdByEtendo: boolean;
  showConfinedSpaceEntry: boolean = false;

  user: User;

  operators$: Observable<Operator[]>;

  uploadedFiles: Array<any> = [];

  notifyOnECTChange: boolean;
  ectCustomerHasViewed: boolean;
  actionsBarProperties: ActionsBarProperties = {} as any;
  actionsBarPropertiesNoPrint: ActionsBarProperties = {} as any;
  variableButtons: Button[];

  etendoIcon = MainIconEtendoComponent;

  @Input() item: WashRequest;
  @Input() itemsList: [WashRequest];
  @Input() hasLoadedBPs: boolean;
  @Input() toggledWashRequestId: string;
  @Output() statusChanged = new EventEmitter<WashRequest>();
  @Output() isEditingChanged = new EventEmitter<{
    isEditing: boolean;
    washRequestId: string;
  }>();
  @Output() printWashId = new EventEmitter<string>();
  @Output() isOpenDetailsChanged = new EventEmitter<{
    isOpen: boolean;
    washRequestId: string;
  }>();
  @Output() updatedItemList = new EventEmitter<boolean>();

  get customerName(): string {
    if (!this.item.customer) {
      return '';
    }

    let customerName = this.item.customer;

    if (this.item.isCod) {
      customerName = `COD - ${customerName}`;
    }

    return customerName.length > 30
      ? customerName.slice(0, 30) + '...'
      : customerName;
  }

  get lastContainedProduct(): string {
    // If food grade, return the latest contained product (lastContainedProduct1Name). Food grade tanks only have 1 compartment.
    if (this.item.foodGrade) {
      return this.item.lastContainedProduct1Name;
    }

    // If not food grade, return the first compartment's last contained product that has service type different of 'Do not clean'.
    for (let index = 1; index <= 5; index += 1) {
      const serviceType = `serviceTypeComp${index}`;
      const lastContainedProduct = `lastContainedProductComp${index}Name`;
      if (
        this.item[serviceType] !== CompartmentServiceType.DoNotClean &&
        this.item[lastContainedProduct]
      ) {
        return this.item[lastContainedProduct];
      }
    }
  }

  get disableActionButtons(): boolean {
    return !this.hasLoadedBPs || this.isProcessing;
  }

  get shouldMarkItemAsNew(): boolean {
    // 432000 = 5 days
    const epochNow = Math.floor(new Date().getTime() / 1000.0);
    return (
      (!this.item.visualizedBy ||
        !this.item.visualizedBy.includes(this.user.id)) &&
      this.item.lastUpdateTime > epochNow - 432000
    );
  }

  get shouldShowObSyncErrorIcon(): boolean {
    if (this.hasQualaWorkerRole && this.item.openBravoSyncError) {
      return true;
    } else if (
      !this.hasQualaWorkerRole &&
      this.item.openBravoSyncError &&
      this.currentMenuOption === MenuOption.Draft
    ) {
      return true;
    } else {
      return false;
    }
  }

  get shouldDisplayOriginalEstimatedCompletionTimeOnDetails(): boolean {
    return (
      (this.item.expectedCompletionTime !==
        this.item.originalExpectedCompletionTime &&
        this.currentMenuOption === MenuOption.Accepted) ||
      this.currentMenuOption === MenuOption.InProgress
    );
  }

  get shouldDisplayWONotConvertedIcon(): boolean {
    return (
      !this.item.workOrderCreated &&
      this.hasQualaWorkerRole &&
      (this.currentMenuOption === MenuOption.Accepted ||
        this.currentMenuOption === MenuOption.NewWashRequests)
    );
  }

  filterFileByFlag(flag: WashRequestFileFlag) {
    return (
      this.item.files &&
      this.item.files.filter((file) => file.customFileFlag === flag).length > 0
    );
  }

  get shouldDisplayExteriorWashImage(): boolean {
    return this.filterFileByFlag(WashRequestFileFlag.ExteriorWash);
  }

  get shouldDisplayCSEImage(): boolean {
    return this.filterFileByFlag(WashRequestFileFlag.CsePermit);
  }

  get conversionStatusToDisplay(): string {
    if (
      this.item.orderConversionStatus ===
        OrderConversionStatuses.MANUAL_CONVERSION_SELECTED &&
      !this.item.workOrderCreated
    ) {
      return 'manual-conversion-chosen';
    }
    if (
      (this.item.workOrderCreated ||
        this.item.orderConversionStatus ===
          OrderConversionStatuses.COMPLETED) &&
      !!this.item.orderConversionMessage
    ) {
      return 'warning';
    }

    if (
      this.item.workOrderCreated ||
      this.item.orderConversionStatus === OrderConversionStatuses.COMPLETED
    ) {
      return 'completed';
    }

    if (this.item.openBravoSyncError) {
      return 'pending';
    }

    if (
      !this.item.washRequestIdOpenBravo ||
      this.item.orderConversionStatus === OrderConversionStatuses.IN_PROGRESS ||
      this.item.orderConversionStatus === OrderConversionStatuses.PENDING ||
      this.item.orderConversionStatus === OrderConversionStatuses.WARNING
    ) {
      return 'running';
    }

    return 'pending';
  }

  get schneiderCompletionStatusToDisplay(): string {
    if (this.authService.hasQualaWorkerRole) {
      return this.item.schneiderCompletionStatus;
    }
  }

  get warningMessage(): string {
    return `
    Successfully Converted${
      this.item.orderConversionMessage
        ? ' - ' + this.item.orderConversionMessage
        : ''
    }\nCheck in OB for more details.
    `;
  }

  get hasQualaRole() {
    return this.authService.hasQualaWorkerRole();
  }

  get hasSyncedWithOb() {
    return !!this.item.washRequestIdOpenBravo;
  }

  get workOrderStatus() {
    return this.item.workOrderStatus;
  }

  isWorkOrderClosedOrCancelled() {
    if (
      this.workOrderStatus === 'Closed' ||
      this.workOrderStatus === 'Cancelled'
    ) {
      return true;
    }
  }

  constructor(
    private washListService: WashListService,
    private location: Location,
    private washItemService: WashItemService,
    private authService: AuthService,
    private toastr: ToastrService,
    private dialogConfirmationService: DialogConfirmationService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private fileItemService: FileItemService,
    private businessPartnerService: BusinessPartnerService,
    private confinedSpaceEntryDialogService: ConfinedSpaceEntryDialogService,
    private timeService: TimeService,
    private operatorsDialogService: OperatorsDialogService,
    private manualStartStopReasonService: ManualStartStopReasonService,
    private startStopConfirmationService: StartStopConfirmationService,
    private loadingDialogService: LoadingDialogService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateActionBarConfig();
    this.toggleDetail();

    if(changes.item && changes.item.previousValue) {
      this.ngOnInit();
    }
  }

  ngOnInit() {
    this.user = this.authService.user;
    this.currentMenuOption = this.route.snapshot.paramMap.get(
      'option'
    ) as MenuOption;
    this.hasQualaWorkerRole = this.authService.hasQualaWorkerRole();
    this.showDetail = false;
    this.createFormControls();
    this.washListService.currentBreakpoint.subscribe((flag) => {
      this.isNotMobile = flag;
      this.actionsBarProperties ? this.updateActionBarConfig() : null;
    });
    this.getBusinessPartner();
    this.isStandardServicePlan();
    this.setServicePlanNameToShow();
    this.displayRecommendedServicePlan();
    this.createdByEtendo = this.item.createdByEtendo;
    const { ectCustomerHasViewed, notifyOnECTChange } =
      this.getNotifyECTPreferences();
    this.ectCustomerHasViewed = ectCustomerHasViewed;
    this.notifyOnECTChange = notifyOnECTChange;
    this.setActionsVisibility();
    this.updateActionBarConfig();
  }

  private updateIsProcessingStatus(isProcessingValue: boolean) {
    this.isProcessing = isProcessingValue;
    this.updateActionBarConfig();
  }

  updateActionBarConfig() {
    const shouldShowToggle =
      this.actionsControl && this.actionsControl.showSpecialPrep;
    this.actionsBarProperties = {
      buttons: [
        {
          icon: 'cancel',
          btnText: 'Reject Exterior Wash Offer',
          btnId: 'reject-exterior-wash-offer',
          btnClick: () => this.declineExteriorWashOffer(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () => this.hasExteriorWashOffer(),
          isProcessing: this.isProcessing,
        },
        {
          icon: 'select_check_box',
          btnText: 'Accept Exterior Wash Offer',
          btnId: 'accept-exteror-wash-offer',
          btnClick: () => this.acceptExteriorWashOffer(),
          buttonStyle: ButtonStyle.PRIMARY,
          filterFn: () => this.hasExteriorWashOffer(),
          isProcessing: this.isProcessing,
        },
        {
          icon: 'print',
          btnText: 'Print request',
          btnId: 'item-print',
          btnClick: () => this.print(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () => this.isNotMobile && !this.createdByEtendo,
        },
        {
          btnText: 'CSE',
          icon: 'guardian',
          btnId: 'item-cse',
          btnClick: () => this.updateCSEData(),
          tooltip: this.getErrorMessageFromCode(
            'work_order_closed_or_cancelled'
          ),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.shouldShowCSEButton() &&
            this.hasQualaRole &&
            !this.createdByEtendo,
          disabledTooltip: !this.isWorkOrderClosedOrCancelled(),
          btnDisabled: this.isWorkOrderClosedOrCancelled(),
        },
        {
          btnText: 'Clone',
          btnId: 'item-clone',
          icon: 'library_add',
          btnClick: () => this.clone(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.item.status !== 'CREDIT_HOLD' && !this.createdByEtendo,
        },
        {
          icon: 'block',
          btnText: 'Cancel',
          btnId: 'item-cancel',
          btnClick: () => this.cancel(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canBeCanceled,
          btnDisabled: this.isProcessing,
          btnDisabledParameter: 'isProcessing',
        },
        {
          icon: 'cancel',
          btnText: 'Reject',
          btnId: 'item-reject',
          btnClick: () => this.reject(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl &&
            this.actionsControl.canBeRejected &&
            !this.createdByEtendo,
          btnDisabled: this.isProcessing,
          btnDisabledParameter: 'isProcessing',
        },
        {
          icon: 'cancel',
          btnText: 'Reject with Heel',
          btnId: 'item-reject-with-heel',
          btnClick: () => this.rejectWithHeel(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canBeRejectedDueToHeel,
          btnDisabled: this.isProcessing,
          btnDisabledParameter: 'isProcessing',
        },
        {
          btnText: 'Edit',
          btnId: 'item-edit',
          icon: 'edit_document',
          btnClick: () => this.edit(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl &&
            this.actionsControl.canBeEdited &&
            !this.isExpiredRequestsOption(),
          tooltip:
            (this.createdByEtendo && this.editButtonDisabledTooltipMessage()) ||
            (this.isWorkOrderClosedOrCancelled() &&
              this.getErrorMessageFromCode('work_order_closed_or_cancelled')),
          disabledTooltip:
            !this.createdByEtendo && !this.isWorkOrderClosedOrCancelled(),
          btnDisabled:
            this.isProcessing ||
            this.createdByEtendo ||
            this.isWorkOrderClosedOrCancelled(),
          btnDisabledParameter: 'isProcessing',
        },
        {
          icon: 'event_repeat',
          btnText: 'Reschedule',
          btnId: 'item-reschedule',
          btnClick: () => this.reschedule(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () => this.shouldShowRescheduleButton(),
          tooltip:
            "Sends the request back to 'Accepted' to be rescheduled in the next time slot",
          disabledTooltip: !this.hasOpenBravoId(),
          btnDisabled: this.isProcessing,
          btnDisabledParameter: 'isProcessing',
        },
        {
          icon: 'task_alt',
          btnText: 'Finish Request',
          btnId: 'item-complete',
          btnClick: () => this.complete(),
          buttonStyle: ButtonStyle.PRIMARY,
          filterFn: () => this.isExpiredRequestsOption(),
          tooltip: this.getTooltipMessage()
            ? this.getTooltipMessage()
            : this.hasOpenBravoId()
              ? 'Wash request was not created in OpenBravo'
              : null,
          disabledTooltip: this.hasOpenBravoId(),
          btnDisabled: !this.hasOpenBravoId() || this.isProcessing,
          btnDisabledParameter: 'isProcessing',
        },
        {
          btnText: 'Accept',
          btnId: 'item-accept',
          icon: 'check_circle',
          btnClick: () => this.validatePOAndExecute(this.acceptFlow.bind(this)),
          buttonStyle: ButtonStyle.PRIMARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canBeAccepted,
          btnDisabled: this.disableActionButtons,
          btnDisabledParameter: 'isProcessing',
        },
        {
          icon: 'send',
          btnText: 'Submit',
          btnId: 'item-submit',
          btnClick: () => this.validatePOAndExecute(this.submitFlow.bind(this)),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canBeSubmitted,
          btnDisabled: this.disableActionButtons,
          btnDisabledParameter: 'isProcessing',
        },
        {
          icon: 'how_to_reg',
          btnText: 'In Person Approval',
          btnId: 'in-person-approval',
          btnClick: () => this.validatePOAndExecute(this.acceptFlow.bind(this)),
          buttonStyle: ButtonStyle.PRIMARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canBeInPersonApproval,
          tooltip:
            this.businessPartner && this.businessPartner.customerBlocking
              ? 'Account on hold. Requests can not be scheduled. Customer needs to contact their sales representative.'
              : '',
          btnDisabled:
            this.businessPartner &&
            this.businessPartner.customerBlocking &&
            this.disableActionButtons,
        },
        {
          icon: 'select_check_box',
          btnText: 'Mark as approved',
          btnId: 'item-mark-as-approved',
          btnClick: () => null,
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canMarkAsApproved,
        },
        {
          btnText: 'Remove Hold',
          btnId: 'item-remove-hold',
          icon: 'close',
          btnClick: () => this.removeHold(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canRemoveHold,
          tooltip:
            'This wash request was created on Etendo. You can’t remove hold or request approval.',
          btnDisabled: this.isProcessing || this.createdByEtendo,
          disabledTooltip: !this.createdByEtendo,
        },
        {
          icon: 'mark_chat_read',
          btnText: 'Request Approval',
          btnId: 'item-request-approval',
          btnClick: () => this.requestHeelApproval(),
          buttonStyle: ButtonStyle.PRIMARY,
          filterFn: () =>
            this.actionsControl &&
            this.actionsControl.canRequestHeelApproval &&
            !this.showHeelApprovalInfo(),
          tooltip:
            'This wash request was created on Etendo. You can’t remove hold or request approval.',
          btnDisabled: this.isProcessing || this.createdByEtendo,
          disabledTooltip: !this.createdByEtendo,
        },
        {
          icon: 'unknown_document',
          btnText: 'Provide More Info',
          btnId: 'item-provide-more-info',
          btnClick: () => this.provideMoreInfo(),
          buttonStyle: ButtonStyle.PRIMARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canProvideMoreInfo,
        },
        {
          btnText: 'Request More Info',
          icon: 'info',
          btnId: 'item-request-more-info',
          btnClick: () => this.requestMoreInfo(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canRequestInfo,
        },
        {
          btnText: 'Deny',
          icon: 'delete',
          btnId: 'item-deny',
          btnClick: () => this.denyHeel(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canDenyHeel,
        },
        {
          btnText: 'Approve',
          icon: 'check_circle',
          btnId: 'item-approve',
          btnClick: () => this.approveHeel(),
          buttonStyle: ButtonStyle.PRIMARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canApproveHeel,
          btnDisabled: this.isProcessing,
        },
        {
          btnText: 'Retry Submit',
          icon: 'send',
          btnId: 'item-retry',
          btnClick: () => this.retrySubmit(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () =>
            this.actionsControl && this.actionsControl.canRetrySubmit,
        },
      ].filter((button) => (button.filterFn ? button.filterFn() : true)),
      label: this.retrieveLabelOfActionsBar(),
      isToggled: this.specialPrep,
      showToggle: shouldShowToggle,
      toggleId: 'special-prep',
    };
    this.actionsBarPropertiesNoPrint = {
      buttons: [
        {
          icon: 'cancel',
          btnText: 'Reject Exterior Wash Offer',
          btnId: 'reject-exterior-wash-offer',
          btnClick: () => this.declineExteriorWashOffer(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () => this.hasExteriorWashOffer(),
          isProcessing: this.isProcessing,
        },
        {
          icon: 'select_check_box',
          btnText: 'Accept Exterior Wash Offer',
          btnId: 'accept-exterior-wash-offer',
          btnClick: () => this.acceptExteriorWashOffer(),
          buttonStyle: ButtonStyle.PRIMARY,
          filterFn: () => this.hasExteriorWashOffer(),
          isProcessing: this.isProcessing,
        },
        {
          icon: 'print',
          btnText: 'Print request',
          btnId: 'item-print',
          btnClick: () => this.print(),
          buttonStyle: ButtonStyle.SECONDARY,
          filterFn: () => !this.createdByEtendo,
        },
        {
          btnText: 'Clone',
          btnId: 'alt-clone',
          icon: 'library_add',
          btnClick: () => this.clone(),
          buttonStyle: ButtonStyle.PRIMARY,
        },
      ].filter((button) => (button.filterFn ? button.filterFn() : true)),
    };
  }

  retrieveLabelOfActionsBar() {
    if (!this.actionsControl) {
      return;
    }

    if (this.actionsControl.showSpecialPrep) {
      return 'Special Prep';
    }

    if (!this.actionsControl.showContainerArrived) {
      return this.actionsControl?.actionMessage;
    }
  }

  getNotifyECTPreferences() {
    const notifyECTPreferenceLocalStorage =
      this.localStorageService.getNotifyECTChangesPreferencesByUser(
        this.user.id
      );
    let notifyECTPreferenceWashRequest =
      this.item.ectCustomerNotificationUsers &&
      this.user.id in this.item.ectCustomerNotificationUsers
        ? this.item.ectCustomerNotificationUsers[this.user.id]
        : undefined;
    const userHasNotificationPreference =
      notifyECTPreferenceWashRequest !== undefined;

    const ectCustomerHasViewed = userHasNotificationPreference ? true : false;
    const notifyOnECTChange = userHasNotificationPreference
      ? notifyECTPreferenceWashRequest
      : notifyECTPreferenceLocalStorage !== undefined
        ? notifyECTPreferenceLocalStorage
        : true;
    return {
      ectCustomerHasViewed,
      notifyOnECTChange,
    };
  }

  hasExteriorWashOffer() {
    return (
      this.item.exteriorWashOffer &&
      this.item.exteriorWashOffer.status === ExteriorWashOfferStatuses.PENDING
    );
  }

  isStandardServicePlan() {
    if (this.item.servicePlan) {
      this.isRedServicePlan =
        this.item.servicePlan.servicePlanName ===
        StandardServicePlanNames.createdByDispatcher
          ? true
          : false;
    }
  }

  setServicePlanNameToShow() {
    const isStatusDraftAndStandardServicePlanDispatcher =
      this.item.status === WashRequestStatus.Draft &&
      !this.hasQualaRole &&
      (this.item.servicePlan && this.item.servicePlan.servicePlanName) ===
        StandardServicePlanNames.createdByDispatcher;
    if (isStatusDraftAndStandardServicePlanDispatcher) {
      this.selectedServicePlanName =
        StandardServicePlanNames.draftCreatedByDispatcher;
    } else {
      this.selectedServicePlanName =
        this.item.servicePlan && this.item.servicePlan.servicePlanName;
    }
  }

  displayRecommendedServicePlan() {
    if (this.item.recommendedServicePlan) {
      const isStandardServicePlan =
        this.item.recommendedServicePlan.servicePlanName ===
          StandardServicePlanNames.createdByDispatcher ||
        this.item.recommendedServicePlan.servicePlanName ===
          StandardServicePlanNames.createdByQuala;
      if (isStandardServicePlan) {
        this.showRecommendedServicePlan = false;
        this.showSelectedServicePlan = true;
        return;
      }

      const isTheRecommendedTheSelected =
        this.item.servicePlan.servicePlanName === // Non-Haz;CWF;Boosted Caustic;HWR;DRY
        this.item.recommendedServicePlan.servicePlanName; // é igual a Non-Haz;CWF;Boosted Caustic;HWR;DRY
      if (isTheRecommendedTheSelected) {
        this.showRecommendedServicePlan = true;
        this.showSelectedServicePlan = false; // devia entrar aqui
        return;
      }

      this.showRecommendedServicePlan = true;
      this.showSelectedServicePlan = true;
      return;
    }

    this.showRecommendedServicePlan = false;
    this.showSelectedServicePlan = true;
  }

  getBusinessPartner() {
    this.businessPartnerService.businessPartners.subscribe(
      (businessPartners) => {
        this.businessPartner = businessPartners.find(
          (item) => item.id === this.item.customerId
        );
      }
    );
  }

  shouldDisplayEctForTerminal(actualTerminal) {
    return this.washListService.shouldDisplayEctForTerminal(actualTerminal);
  }

  setActionsVisibility() {
    const loggedUserBusinessPartner = this.authService.getBusinessPartnerId();
    this.actionsControl = this.washItemService.getAllowedActions(
      this.item,
      this.hasQualaWorkerRole,
      loggedUserBusinessPartner,
      this.currentMenuOption
    );
  }

  showHeelApprovalInfo() {
    return this.item.exceptionType;
  }

  createFormControls() {
    this.specialPrep = new UntypedFormControl(this.item.specialPrep);

    this.specialPrep.valueChanges
      .pipe(
        switchMap((value) =>
          this.washListService.updateSpecialPrep(this.item.id, value)
        )
      )
      .subscribe();
  }

  showCompartment(compartmentNumber) {
    return compartmentNumber <= this.item.compartmentsQty;
  }

  toggleDetail() {
    if (this.toggledWashRequestId === this.item.id) {
      this.washItemService
        .getWashRequestRevision(this.item.id)
        .then((data) => {
          this.revisionFields = data;
        })
        .catch((error) => {});
      this.washItemService.getHistoric(this.item.id).then((data) => {
        this.heelDetails = data || [];
        this.heelDetails = this.heelDetails.sort((a, b) =>
          a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0
        );
      });
    }
    this.isOpenDetailsChanged.emit({
      washRequestId: this.item.id,
      isOpen: this.toggledWashRequestId === this.item.id,
    });
  }

  getIsHeelPreApprovedMsg() {
    return 'This request was submitted with the Heel Handling already approved.';
  }

  submitToApproval() {
    this.updateIsProcessingStatus(true);
    this.washListService.submitToApproval(this.item.id).subscribe(
      (response) => {
        if (response.data.status === 202) {
          if (this.authService.hasQualaWorkerRole) {
            this.toastr.warning(
              '',
              'Account on hold. Requests will not be scheduled. Customer needs to contact the sales representative.'
            );
          } else {
            this.toastr.warning(
              '',
              'Account on hold. Requests will not be scheduled. Please contact your sales representative.'
            );
          }
        } else if (
          response.data.status === 207 &&
          this.item.createdByRole === 'CustomerDispatcher'
        ) {
          this.toastr.warning(
            '',
            'Terminal is closed right now. Quala will evaluate it as soon as the location starts their operation.'
          );
        } else {
          this.toastr.success('', 'Request submitted!');
        }
        this.updateIsProcessingStatus(false);
      },
      (error) => {
        this.updateIsProcessingStatus(false);
        let errorMessage;
        if (error.status === 422 && error.error.details) {
          errorMessage = error.error.details;
        }
        this.toastErrorMessage(errorMessage || 'Error submitting the request!');
      }
    );
  }

  accept(preventOrderConversion?: boolean) {
    this.updateIsProcessingStatus(true);

    this.washListService.accept(this.item.id, preventOrderConversion).subscribe(
      (_) => {
        this.updateIsProcessingStatus(false);
        this.toastr.success('', 'Request accepted!');
      },
      (error) => {
        this.updateIsProcessingStatus(false);
        let errorMessage;
        if (error.status === 422 && error.error.details) {
          errorMessage = error.error.details;
        }
        this.toastErrorMessage(errorMessage || 'Error submitting the request!');
      }
    );
  }

  cancel() {
    this.showDialogConfirmation('Cancel request?', 'cancel', 'Cancel').then(
      (dialogResult) => {
        if (dialogResult) {
          this.washListService.cancel(this.item.id).subscribe(
            (_) => {
              this.toastr.success('', 'Request cancelled!');
            },
            (error) => {
              this.toastErrorMessage('Error cancelling the request!');
            }
          );
        }
      }
    );
  }

  rejectWithHeel() {
    this.updateIsProcessingStatus(true);

    if (!this.hasQualaWorkerRole) {
      return;
    }

    const dialogRef = this.dialog.open(WashRejectWithHeelComponent);

    dialogRef.afterClosed().subscribe({
      next: (reasonObj) => {
        if (!reasonObj) {
          return;
        }

        this.washListService.reject(this.item.id, reasonObj, true).subscribe(
          (_) => {
            this.toastr.success('', 'Request rejected!');
          },
          () => {
            this.toastErrorMessage('Error rejecting the request!');
          },
          () => {
            this.updateIsProcessingStatus(false);
          }
        );
      },
    });
  }

  reject() {
    this.updateIsProcessingStatus(true);
    if (this.hasQualaWorkerRole) {
      this.washListService
        .getReasonsForReject()
        .then((reasons) => {
          const dialogRef = this.dialog.open(WashRejectComponent, {
            data: reasons,
          });

          dialogRef.afterClosed().subscribe((reasonObj) => {
            if (!reasonObj || !reasonObj.code) {
              return;
            }

            this.washListService.reject(this.item.id, reasonObj).subscribe(
              (_) => {
                this.toastr.success('', 'Request rejected!');
              },
              () => {
                this.toastErrorMessage('Error rejecting the request!');
              }
            );
          });
        })
        .finally(() => {
          this.updateIsProcessingStatus(false);
        });
    } else {
      this.showDialogConfirmation('Reject request?', 'cancel', 'Reject')
        .then((dialogResult) => {
          if (!dialogResult) {
            return;
          }

          this.washListService.reject(this.item.id, { code: 'INAD' }).subscribe(
            (_) => {
              this.toastr.success('', 'Request rejected!');
            },
            (error) => {
              this.toastErrorMessage('Error rejecting the request!');
            }
          );
        })
        .finally(() => {
          this.updateIsProcessingStatus(false);
        });
    }
  }

  acceptExteriorWashOffer() {
    this.updateIsProcessingStatus(true);
    this.washListService
      .handleExteriorWashOfferAction(
        this.user.id,
        this.item.exteriorWashOffer.id,
        ExteriorWashOfferStatuses.ACCEPTED
      )
      .then(() => {
        this.updateIsProcessingStatus(false);
        this.toastr.success('', 'Offer accepted!');
      })
      .catch((error) => {
        const response = error.response;
        this.updateIsProcessingStatus(false);
        let errorMessage;
        if (
          (response.status === 406 || response.status === 498) &&
          response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        this.toastErrorMessage(errorMessage || 'Error accepting the offer!');
      })
      .finally(() => {
        this.item.exteriorWashOffer.status = ExteriorWashOfferStatuses.ACCEPTED;
      });
  }

  declineExteriorWashOffer() {
    this.updateIsProcessingStatus(true);
    this.washListService
      .handleExteriorWashOfferAction(
        this.user.id,
        this.item.exteriorWashOffer.id,
        ExteriorWashOfferStatuses.DECLINED
      )
      .then(() => {
        this.updateIsProcessingStatus(false);
        this.toastr.success('', 'Offer declined!');
      })
      .catch((error) => {
        const response = error.response;
        this.updateIsProcessingStatus(false);
        let errorMessage;
        if (
          (response.status === 406 || response.status === 498) &&
          response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        this.toastErrorMessage(errorMessage || 'Error declining the offer!');
      })
      .finally(() => {
        this.item.exteriorWashOffer.status = ExteriorWashOfferStatuses.DECLINED;
      });
  }

  edit() {
    if (this.item.status === 'COMPLETED') {
      this.editCompletedWashRequest();
      return;
    }
    this.isEditing = !this.isEditing;
    this.isEditingChanged.emit({
      isEditing: this.isEditing,
      washRequestId: this.item.id,
    });
  }

  requestHeelApproval() {
    this.isRequestHeelApproval = true;
  }

  approveHeel() {
    this.isHeelManagement = true;
    this.heelSelectedAction = HeelActions.ACCEPT;
  }

  denyHeel() {
    this.isHeelManagement = true;
    this.heelSelectedAction = HeelActions.DENY;
  }

  requestMoreInfo() {
    this.isHeelManagement = true;
    this.heelSelectedAction = HeelActions.REQUEST_INFO;
  }

  provideMoreInfo() {
    this.isHeelManagement = true;
    this.heelSelectedAction = HeelActions.PROVIDE_INFO;
  }

  removeHold() {
    this.item.pendingHeelApproval = false;
    this.updateIsProcessingStatus(true);
    this.washListService.removeHold(this.item.id).subscribe(
      (_) => {
        this.updateIsProcessingStatus(false);
        this.toastr.success('', 'Request removed from hold!');
      },
      (error) => {
        this.updateIsProcessingStatus(false);
        this.toastErrorMessage('Error removing hold request!');
      }
    );
  }

  print() {
    this.printWashId.emit(this.item.id);
    setTimeout(() => {
      window.print();
    }, 500);
  }

  clone() {
    this.showDialogConfirmation('Clone request?', 'library_add', 'Yes').then(
      (dialogResult) => {
        if (dialogResult) {
          this.washListService.clone(this.item.id).then(
            (_) => {
              this.washListService
                .loadByOption(this.currentMenuOption)
                .catch((error) => {
                  throw error;
                });
              this.toastr.success('', 'Request cloned and saved as Draft!');
            },
            (error) => {
              this.toastErrorMessage('Error cloning wash request!');
            }
          );
        }
      }
    );
  }

  isExpiredRequestsOption() {
    return this.currentMenuOption === MenuOption.Expired;
  }

  timestampFromTerminal(timestamp: number) {
    const timestampInSecondUtc =
      this.timeService.getTimestampInSecondsAsUTC(timestamp);
    return timestampInSecondUtc * 1000;
  }

  shouldShowRescheduleButton(): boolean {
    const isExpired = this.isExpiredRequestsOption();
    const acceptedOrScheduledStatuses = [
      WashRequestStatus.Accepted,
      WashRequestStatus.Scheduled,
    ];
    const isAcceptedOrScheduled = acceptedOrScheduledStatuses.includes(
      this.item.status
    );
    return isExpired && this.hasOpenBravoId() && isAcceptedOrScheduled;
  }

  hasOpenBravoId(): boolean {
    return !!this.item.washRequestIdOpenBravo;
  }

  removeWashRequestFromList() {
    const index = this.itemsList.findIndex((item) => item.id === this.item.id);
    this.itemsList.splice(index, 1);
    this.updatedItemList.emit(true);
  }

  reloadPage() {
    this.location.go(this.location.path());
    window.location.reload();
  }

  editCompletedWashRequest() {
    let startTime;
    let completeTime;
    return this.showStartStopConfirmationDialog({
      startTime: this.timeService.getTimestampInSecondsAsUTC(
        this.item.startTime
      ),
      stoppedAt: this.timeService.getTimestampInSecondsAsUTC(
        this.item.completeTime
      ),
      currentOption: this.currentMenuOption,
    }).then((startStopDialogResult) => {
      if (!startStopDialogResult) return;
      startTime = startStopDialogResult.startTime;
      completeTime = startStopDialogResult.completeTime;
      return this.showManualStartStopReasonDialog().then(
        (reasonDialogResult) => {
          if (!reasonDialogResult) return;

          this.loadingDialogService.openDialog('Saving...');

          const { additionalComments, code } = reasonDialogResult;
          const manualStartStopData = {
            startTime,
            completeTime,
            reason: {
              additionalComments,
              code,
            },
          };
          return this.washListService
            .shortenedCompletion(this.item.id, manualStartStopData)
            .subscribe(
              () => {
                this.isProcessing = false;

                let toastText;
                if (
                  this.item.status === WashRequestStatus.Accepted ||
                  WashRequestStatus.Scheduled
                ) {
                  toastText = 'Request started and finished at the chosen time';
                } else {
                  toastText = 'Request completed at the chosen time';
                }

                this.item.startTime = startTime;
                this.item.completeTime = completeTime;
                this.setActionsVisibility();
                this.loadingDialogService.closeDialog();

                this.toastr.success('', toastText);
              },
              (error) => {
                const errorMessage = this.getErrorMessageFromError(
                  error,
                  'Error submitting your completion request.'
                );

                this.toastr.error('', errorMessage);
                this.isProcessing = false;
                this.loadingDialogService.closeDialog();
              }
            );
        }
      );
    });
  }

  complete() {
    let startTime;
    let completeTime;

    this.washListService._operators.pipe(take(1)).subscribe(() => {
      return this.operatorsDialogService
        .openDialog(
          this.washListService.dataStore.operators,
          this.washListService.dataStore.recentOperators
        )
        .then((operatorId) => {
          if (!operatorId) return;

          return this.showStartStopConfirmationDialog({
            startTime: this.timeService.getNowAsUTC(),
            stoppedAt: this.timeService.getNowAsUTC(),
            currentOption: this.currentMenuOption,
          }).then((startStopDialogResult) => {
            if (!startStopDialogResult) return;

            startTime = startStopDialogResult.startTime;
            completeTime = startStopDialogResult.completeTime;

            return this.showManualStartStopReasonDialog().then(
              (reasonDialogResult) => {
                if (!reasonDialogResult) return;

                const { additionalComments, code } = reasonDialogResult;

                const manualStartStopData = {
                  startTime,
                  completeTime,
                  reason: {
                    additionalComments,
                    code,
                  },
                };

                return this.washListService
                  .shortenedCompletion(
                    this.item.id,
                    manualStartStopData,
                    operatorId
                  )
                  .subscribe(
                    () => {
                      this.isProcessing = false;
                      this.item.status === WashRequestStatus.Accepted ||
                      WashRequestStatus.Scheduled
                        ? this.toastr.success(
                            '',
                            'Request started and finished with the current timestamp'
                          )
                        : this.toastr.success(
                            '',
                            'Request finished with the current timestamp'
                          );
                      this.removeWashRequestFromList();
                    },
                    () => {
                      this.toastr.error(
                        '',
                        'Error submitting your completion request.'
                      );
                      this.isProcessing = false;
                    }
                  );
              }
            );
          });
        });
    });
  }

  getTooltipMessage(): string {
    return this.item.status === WashRequestStatus.Accepted ||
      WashRequestStatus.Scheduled
      ? 'Start and Finish the request with the current timestamp'
      : 'Finish the request with the current timestamp';
  }

  reschedule() {
    this.washListService.forceAutoSchedule(this.item.id).subscribe(
      () => {
        this.isProcessing = false;
        this.toastr.success(
          '',
          'Request sent to "Accepted" to be rescheduled in the next time slot'
        );
        this.removeWashRequestFromList();
      },
      () => {
        this.toastr.error('', 'Error submitting your rescheduling request.');
        this.isProcessing = false;
      }
    );
  }

  toastErrorMessage(errorMessage) {
    this.toastr.error('', errorMessage);
  }

  showDialogConfirmation(question, icon, action) {
    return this.dialogConfirmationService.openDialog({
      title: question,
      icon,
      action,
    });
  }

  retrySubmit() {
    this.isProcessing = true;
    this.washListService.retrySubmit(this.item.id).subscribe(
      () => {
        this.isProcessing = false;
        this.toastr.success('', 'Request submitted!');
      },
      () => {
        this.toastr.error('', 'Error submitting your wash request.');
        this.isProcessing = false;
      }
    );
  }

  validatePOAndExecute = async (fn: () => void) => {
    // Validate PO
    const poValidationResult = this.validatePONumber();

    // If valid PO, create wash request
    if (poValidationResult.isValid) {
      await fn();
      return;
    }

    // Quala employees are not allowed to submit or accept invalid PO numbers
    // If invalid, warn or block the user before submitting
    const shouldBlockIfInvalidPO = this.authService.hasQualaWorkerRole();
    if (shouldBlockIfInvalidPO) {
      this.toastr.error(poValidationResult.message, 'Invalid PO Number');
      return;
    }

    // Otherwise, just show a confirmation dialog
    const result = await this.dialogConfirmationService.openDialog({
      text: `${poValidationResult.message}. Continue?`,
      icon: 'check_circle',
      action: 'Yes',
      title: 'Invalid PO Number',
    });
    if (!result) {
      // User decided to fix PO number
      return;
    }

    await fn();
  };

  private validatePONumber() {
    const customer =
      this.businessPartnerService.dataStore.businessPartners.find(
        (item) => item.id === this.item.customerId
      );
    return this.washItemService.applyPOValidation({
      businessPartner: customer,
      poNumber: this.item.poNumber,
      poNumberForPrep: this.item.poNumberForPrep,
    });
  }

  openImageOverlay(file) {
    if (file.url) {
      const data = {
        imgUrl: file.url,
      };
      this.dialog.open(ExpandedImageComponent, { data });
    } else {
      this.fileItemService.getFileUrl(file.id).then((imgUrl) => {
        file.url = imgUrl;
        const data = {
          imgUrl,
        };
        this.dialog.open(ExpandedImageComponent, { data });
      });
    }
  }

  async acceptFlow() {
    if (this.hasQualaWorkerRole) {
      const averageAutomatedConversionTime =
        await this.washListService.getAverageAutomatedConversionTime(); // '1min & 23s';

      const washRequestToAccept = this.item;
      const canSubmitRequest = this.checkIfCanSubmit();

      if (washRequestToAccept.workOrderCreated) {
        this.displayOverlayPopUp(
          'Accepting Request',
          PopUpActionType.justAccept,
          null,
          washRequestToAccept,
          canSubmitRequest,
          null,
          null
        );
      } else {
        this.displayOverlayPopUp(
          'Accepting Request',
          PopUpActionType.accept,
          PopUpActionType.acceptWithoutOrderConversion,
          washRequestToAccept,
          canSubmitRequest,
          popUpDisclaimers.accept,
          averageAutomatedConversionTime
        );
      }

      return;
    }
    this.accept();
  }

  async submitFlow() {
    if (this.hasQualaWorkerRole) {
      const washRequestToSubmit = this.item;
      const canSubmitRequest = this.checkIfCanSubmit();
      this.displayOverlayPopUp(
        'Submit for Customer Approval',
        PopUpActionType.submit,
        null,
        washRequestToSubmit,
        canSubmitRequest,
        popUpDisclaimers.submit
      );
      return;
    }
    this.submitToApproval();
  }

  private checkIfCanSubmit() {
    if (this.item.servicePlan && this.item.servicePlan.servicePlanName) {
      return this.item.servicePlan.servicePlanName ===
        StandardServicePlanNames.servicePlanNotFound ||
        this.item.servicePlan.servicePlanName ===
          StandardServicePlanNames.createdByDispatcher
        ? false
        : true;
    }
  }

  displayOverlayPopUp(
    actionHeader: string,
    actionLabel: string,
    secondaryActionLabel: string,
    washRequest: WashRequest,
    canSubmit: boolean,
    actionDisclaimer?: string,
    averageAutomatedConversionTime?: string
  ) {
    const dialogRef = this.dialog.open<
      ServicePlanPopUpOverlayComponent,
      ServicePlanPopUpInputModel
    >(ServicePlanPopUpOverlayComponent, {
      data: {
        actionHeader,
        actionLabel,
        secondaryActionLabel,
        washRequest,
        canSubmit,
        actionDisclaimer,
        averageAutomatedConversionTime,
      },
      panelClass: 'service-plan-popup-overlay',
    });

    dialogRef.beforeClosed().subscribe((overlayData) => {
      if (!overlayData.submit) {
        this.isProcessing = false;
        return;
      }
      if (
        actionLabel === PopUpActionType.accept ||
        actionLabel === PopUpActionType.justAccept
      ) {
        this.accept(overlayData.preventOrderConversion);
        return;
      }
      if (actionLabel === PopUpActionType.submit) {
        this.submitToApproval();
      }
    });
  }

  shouldShowCSEButton(): boolean {
    const cseMenuOptions = [
      MenuOption.Accepted,
      MenuOption.InProgress,
      MenuOption.Hold,
      MenuOption.Completed,
      MenuOption.Expired,
      MenuOption.Pending,
    ];

    if (this.currentMenuOption === MenuOption.Accepted) {
      return this.item.workOrderCreated;
    }

    return cseMenuOptions.includes(this.currentMenuOption);
  }

  updateCSEData() {
    const currentCseData = {
      files: this.item.files,
      workPerformedBy: this.item.workPerformedBy,
      confinedEntry: this.item.confinedEntry,
      confinedEntryType: this.item.confinedEntryType,
    };

    this.washListService._operators.pipe(take(1)).subscribe(() => {
      this.confinedSpaceEntryDialogService
        .openCSEDialog(this.item.id, currentCseData, this.workOrderStatus)
        .then((cseData) => {
          if (!cseData) {
            return;
          }

          this.item.files = cseData.confinedEntryFiles;
          this.item.confinedEntry = cseData.confinedEntry;
          this.item.confinedEntryType = cseData.confinedEntryType;
          this.item.workPerformedBy = cseData.workPerformedBy;

          this.toastr.success('', 'CSE data updated successfully.');
        })
        .catch((error) => {
          const errorMessage = this.getErrorMessageFromError(
            error,
            'Failed to update CSE data.'
          );

          this.toastr.error('', errorMessage);
        });
    });
  }

  showManualStartStopReasonDialog() {
    return this.manualStartStopReasonService.openDialog();
  }

  showStartStopConfirmationDialog(data) {
    return this.startStopConfirmationService.openDialog({
      ...data,
      showConfinedEntryForm: false,
    });
  }

  editButtonDisabledTooltipMessage() {
    if (!this.hasQualaRole) {
      return 'This request cannot be edited due to the way it was created.';
    }

    return "This wash request was created on Etendo and can't be edited.";
  }

  getMessageForExternalSystem() {
    if (!this.hasQualaRole) {
      return 'This request was created in an external system by the Terminal. For more details or updates, please contact the Terminal Manager.';
    }
    return 'This request was created in Etendo and added to the Schedule. Currently, it cannot be edited in OnTrax. For updates to the request information, please proceed to OpenBravo.';
  }

  getErrorMessageFromCode(errorCode) {
    return ErrorMessages[errorCode];
  }

  getErrorMessageFromError(error, defaultMessage) {
    const apiError = error.response?.data?.message;
    return (
      (!!apiError && this.getErrorMessageFromCode(apiError)) ||
      apiError ||
      defaultMessage
    );
  }

  openUrl() {
    this.washListService.openEtendoUrl(
      this.item.washRequestIdOpenBravo,
      this.item.workOrderId,
      this.item.createdByEtendo
    );
  }

  getButtonLabel() {
    return this.washListService.getEtendoButtonLabel(
      this.item.workOrderId,
      this.item.createdByEtendo
    );
  }

  updateUserECTNotificationPreferences(washRequestId: string, userId: string) {
    this.notifyOnECTChange = !this.notifyOnECTChange;
    const notifyECTChangesLocalStorage =
      this.localStorageService.getNotifyECTChangesPreferencesByUser(
        this.user.id
      );
    if (this.notifyOnECTChange !== notifyECTChangesLocalStorage) {
      const newNotifyECTChangesLocalStorage = this.notifyOnECTChange;
      this.localStorageService.setNotifyECTPreferencesByUser(
        userId,
        newNotifyECTChangesLocalStorage
      );
    }
    this.updateUserECTPreferences(
      washRequestId,
      userId,
      this.notifyOnECTChange
    );
  }

  updateEctCustomerHasViewed(washRequestId: string, userId: string) {
    this.ectCustomerHasViewed = !this.ectCustomerHasViewed;
    this.updateUserECTPreferences(
      washRequestId,
      userId,
      this.notifyOnECTChange
    );
  }

  updateUserECTPreferences(
    washRequestId: string,
    userId: string,
    notifyOnECTChange: boolean
  ) {
    const tankNumber = this.item.containerNumber;
    const errorMessage = `There was an error updating you preference for the Estimated Completion Time change notification, for  the request ${tankNumber}. Please review and retry again in a few minutes`;
    this.washItemService
      .updateUserECTPreferences(washRequestId, userId, notifyOnECTChange)
      .catch((error) => {
        this.toastr.error(errorMessage);
      });
  }
}
