import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  AfterViewChecked,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  ControlContainer,
  FormGroupDirective,
} from '@angular/forms';
import {
  NonConformityReport,
  NonConformityReportStatus,
} from '../../non-conformity-report.model';
import { APIService } from 'src/app/core/API.service';

@Component({
  selector: 'app-investigation-form-section',
  templateUrl: './investigation-form-section.component.html',
  styleUrls: ['./investigation-form-section.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class InvestigationFormSectionComponent
  implements OnInit, AfterViewChecked
{
  investigationForm: UntypedFormGroup;

  incidentResponsibilityOptions;
  remedialTrainingProvidedOptions;

  @Input() ncrInput: NonConformityReport;

  constructor(
    private parent: FormGroupDirective,
    private apiService: APIService,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    const initialValues = this.getObjectValues();
    this.investigationForm = new UntypedFormGroup({
      // Actions
      immediateActionsNeeds: new UntypedFormControl(
        initialValues.immediateActionsNeeds
      ),
      correctiveAction: new UntypedFormControl(initialValues.correctiveAction),
      validationCorrectiveAction: new UntypedFormControl(
        initialValues.validationCorrectiveAction
      ),
      rootCause: new UntypedFormControl(initialValues.rootCause),

      // Responsible
      immediateActionsNeedsResponsible: new UntypedFormControl(
        initialValues.immediateActionsNeedsResponsible
      ),
      correctiveActionResponsible: new UntypedFormControl(
        initialValues.correctiveActionResponsible
      ),
      validationCorrectiveActionResponsible: new UntypedFormControl(
        initialValues.validationCorrectiveActionResponsible
      ),
      rootCauseResponsible: new UntypedFormControl(
        initialValues.rootCauseResponsible
      ),

      // Date
      rootCauseCompletionDate: new UntypedFormControl(
        initialValues.rootCauseCompletionDate
      ),
      correctiveActionCompletionDate: new UntypedFormControl(
        initialValues.correctiveActionCompletionDate
      ),
      validationCorrectiveActionCompletionDate: new UntypedFormControl(
        initialValues.validationCorrectiveActionCompletionDate
      ),
      immediateActionsNeedsCompletionDate: new UntypedFormControl(
        initialValues.immediateActionsNeedsCompletionDate
      ),

      // General
      sameResponsible: new UntypedFormControl(initialValues.sameResponsible),
      actionsResponsible: new UntypedFormControl(
        initialValues.actionsResponsible
      ),
      incidentResponsibility: new UntypedFormControl(
        initialValues.incidentResponsibility
      ),
      remedialTrainingProvided: new UntypedFormControl(
        initialValues.remedialTrainingProvided
      ),
    });

    this.parent.form.addControl('investigation', this.investigationForm);
    this.disableFieldsFromEdit();
    this.loadInitialData();
  }

  loadInitialData() {
    this.apiService.ListNcrRemedialTrainingProvided().then((list) => {
      this.remedialTrainingProvidedOptions = list.items.map(
        (item) => item.displayName
      );
    });
    this.apiService.ListNcrIncidentResponsibility().then((list) => {
      this.incidentResponsibilityOptions = list.items.map(
        (item) => item.displayName
      );
    });
  }

  get isSameResponsible(): boolean {
    return !!this.investigationForm.controls.sameResponsible.value;
  }

  get rootCause(): string {
    return (
      (this.parent.form.controls.fiveWhys &&
        this.parent.form.controls.fiveWhys.value.rootCause) ||
      ''
    );
  }

  get rootCauseResponsible(): string {
    return (
      (this.parent.form.controls.fiveWhys &&
        this.parent.form.controls.fiveWhys.value.rootCauseResponsible) ||
      ''
    );
  }

  get rootCauseCompletionDate(): string {
    return (
      (this.parent.form.controls.fiveWhys &&
        this.parent.form.controls.fiveWhys.value.rootCauseCompletionDate) ||
      ''
    );
  }

  private getSafe(func: () => any) {
    try {
      return func();
    } catch (e) {
      return undefined;
    }
  }

  private disableFieldsFromEdit() {
    if (this.ncrInput.status === NonConformityReportStatus.Completed) {
      Object.keys(this.investigationForm.controls).forEach((key) => {
        this.investigationForm.get(key).disable();
      });
    }
  }

  private getObjectValues() {
    const inputData: NonConformityReport = this.ncrInput || ({} as any);
    return {
      immediateActionsNeeds:
        this.getSafe(
          () => inputData.investigation.immediateAction.description
        ) || '',
      correctiveAction:
        this.getSafe(
          () => inputData.investigation.correctiveAction.description
        ) || '',
      validationCorrectiveAction:
        this.getSafe(
          () => inputData.investigation.validationCorrectiveAction.description
        ) || '',
      rootCause:
        this.getSafe(() => inputData.fiveWhys.rootCause.description) || '',

      immediateActionsNeedsResponsible:
        this.getSafe(
          () => inputData.investigation.immediateAction.responsible
        ) || '',
      correctiveActionResponsible:
        this.getSafe(
          () => inputData.investigation.correctiveAction.responsible
        ) || '',
      validationCorrectiveActionResponsible:
        this.getSafe(
          () => inputData.investigation.validationCorrectiveAction.responsible
        ) || '',
      rootCauseResponsible:
        this.getSafe(() => inputData.fiveWhys.rootCause.responsible) || '',

      rootCauseCompletionDate:
        this.getSafe(() => inputData.fiveWhys.rootCause.completionDate) || '',
      correctiveActionCompletionDate:
        this.getSafe(
          () => inputData.investigation.correctiveAction.completionDate
        ) || '',
      validationCorrectiveActionCompletionDate:
        this.getSafe(
          () =>
            inputData.investigation.validationCorrectiveAction.completionDate
        ) || '',
      immediateActionsNeedsCompletionDate:
        this.getSafe(
          () => inputData.investigation.immediateAction.completionDate
        ) || '',

      sameResponsible:
        this.getSafe(
          () => inputData.investigation.responsible.sameForAllActions
        ) || false,
      actionsResponsible:
        this.getSafe(
          () => inputData.investigation.correctiveAction.responsible
        ) || '',
      incidentResponsibility:
        this.getSafe(() => inputData.incidentResponsibility) || '',
      remedialTrainingProvided:
        this.getSafe(() => inputData.remedialTraining) || '',
    };
  }
}
