import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  AbstractControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { invalid, unix } from 'moment';
import { ToastrService } from 'ngx-toastr';
import { TimeService } from 'src/app/core/time.service';
import { NcrService } from '../ncr.service';
import { NCRCreateFieldsEnum } from './ncr-create-fields.enum';
import {
  NonConformityReport,
  Investigation,
  FiveWhys,
  NonConformityReportStatus,
} from '../non-conformity-report.model';
import { DataFromWorkOrder, NcrFormObject } from './ncr-master-form.model';
import { NcrMasterFormServices } from './ncr-master-form.services';
import { UploadedFile } from 'src/app/shared/file-upload/file-upload.model';
import { APIService } from 'src/app/core/API.service';
import { PublicAPIService } from 'src/app/core/PublicAPI.service';
import { AuthService } from 'src/app/core/auth/auth.service';
import { WarehouseService } from 'src/app/core/services/warehouse.service';
import { MasterAccountService } from 'src/app/core/services/master-account.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { Warehouse } from 'src/app/core/model/warehouse.model';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';

export interface MasterFormOutput {
  data?: NcrFormObject;
  error?: ValidationErrors;
}

@Component({
  selector: 'app-ncr-master-form',
  templateUrl: './ncr-master-form.component.html',
  styleUrls: ['./ncr-master-form.component.scss'],
})
export class NcrMasterFormComponent implements OnInit, OnDestroy, OnChanges {
  baseFormValues: NcrFormObject;
  loadingWorkOrder = false;
  displayValidationMessage = false;
  validationMessage: string;
  uploadedFiles: UploadedFile[];
  now = unix(this.timeService.getNowAsUTC()).second(0).millisecond(0).unix();
  triedSubmit: boolean;
  formValidationErrorMessage = 'Provide a value for this field.';

  @Input() ncrInput?: NonConformityReport;

  @Input() isQualaWorker: boolean;
  @Input() isUnauthenticated: boolean;

  @Input() isEditing: boolean;

  @Input() loadingSubmit: boolean;
  @Input() loadingCancel: boolean;

  @Output() ncrSubmitEvent = new EventEmitter<MasterFormOutput>();
  @Output() ncrCancelEvent = new EventEmitter<{ formTouched: boolean }>();

  private masterAccountsSubscription: Subscription;
  private isLoadingBPsSubscription: Subscription;
  equipmentOwnerLabel: string;
  signedStatement: string[] = [];
  whoHaveBeenNotified: string[] = [];
  containerTypes: Array<string> = [
    'Tank Trailer',
    'ISO Container',
    'IBC / Tote',
    'Railcar',
    'Other',
  ];
  issueTypes = [];
  formattedWarehouses = [];
  warehousesMap: { [key: string]: Warehouse } = {};
  shiftStartTimes: string[] = [];
  registerForm: FormGroup;
  isDispatcher: boolean;
  masterAccounts = [];
  isLoadingBPs: boolean;
  isDispatcherWithCustomerId: boolean;
  showPeopleStatementValidationMessage: boolean;
  showPeopleNotifiedValidationMessage: boolean;
  internalNcr: boolean;
  actionsBarConfig: ActionsBarProperties;

  constructor(
    private timeService: TimeService,
    private ncrMasterFormServices: NcrMasterFormServices,
    private toastr: ToastrService,
    private ncrService: NcrService,
    private apiService: APIService,
    private authService: AuthService,
    private masterAccountService: MasterAccountService,
    private warehouseService: WarehouseService
  ) {}

  ngOnChanges(): void {
    if (this.actionsBarConfig) {
      this.configureActionsBar();
    }
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.setEquipmentOwnerLabel();
    this.disableFieldsFromEdit();
    this.loadInitialData();
    this.setupValueChanges();
    this.checkEditingMode();

    this.isDispatcher =
      this.authService.user && this.authService.hasDispatcherRole();

    this.warehouseService.allWarehouses$.subscribe((warehouses) => {
      ({
        formattedWarehouses: this.formattedWarehouses,
        warehousesMap: this.warehousesMap,
      } = this.ncrService.buildWarehouseData(warehouses));
    });
    this.isDispatcherWithCustomerId =
      this.checkIfUserIsDispatcherWithCustomerId();
    this.subscribeToLoadingStatus();
    this.subscribeToMasterAccounts();
    this.setAutoSelectedAccount();
    this.configureActionsBar();
  }

  loadInitialData() {
    const apiServiceToCall = this.isUnauthenticated
      ? new PublicAPIService()
      : this.apiService;

    apiServiceToCall.ListNcrShiftStartTimes().then((list) => {
      this.shiftStartTimes = this.sort(list.items).map(
        (obj) => obj.displayName
      );
    });
    apiServiceToCall.ListNcrIssueType().then((list) => {
      this.issueTypes = this.sort(list.items);
    });
    apiServiceToCall.ListNcrSignedStatement().then((list) => {
      this.signedStatement = this.sort(list.items).map(
        (obj) => obj.displayName
      );
    });
    apiServiceToCall.ListNcrWhoHaveBeenNotified().then((list) => {
      this.whoHaveBeenNotified = this.sort(list.items).map(
        (obj) => obj.displayName
      );
    });
  }

  ngOnDestroy() {
    this.masterAccountsSubscription.unsubscribe();
    this.isLoadingBPsSubscription.unsubscribe();
  }

  sort(list: any[]) {
    return list.sort((obj, otherObj) => (obj.order > otherObj.order ? 1 : -1));
  }

  get allowWOSearch(): boolean {
    return !!(this.registerForm.controls.workOrder.value as string).match(
      /^[wW][oO]\d{8}$/
    );
  }

  get hasDamage(): boolean {
    return !!this.registerForm.controls.wasEquipmentDamaged.value;
  }

  get showWhoGotHurt(): boolean {
    return !!this.registerForm.controls.wasAnyoneInjured.value;
  }

  get investigationAvailable(): boolean {
    return (
      this.ncrInput &&
      this.ncrInput.id &&
      this.ncrInput.status === NonConformityReportStatus.InAnalysis
    );
  }

  get waitingApprovalAvailable(): boolean {
    return (
      this.ncrInput &&
      this.ncrInput.id &&
      this.ncrInput.status === NonConformityReportStatus.WaitingApproval
    );
  }

  get ncrIsCompleted(): boolean {
    return (
      this.ncrInput &&
      this.ncrInput.id &&
      this.ncrInput.status === NonConformityReportStatus.Completed
    );
  }

  get isACreationPage(): boolean {
    return this.ncrInput === undefined;
  }

  get emailLabel(): string {
    return this.internalNcr ? 'Contact Email *' : 'Customer Email *';
  }

  get contactNameLabel(): string {
    return this.internalNcr ? 'Contact Name *' : 'Customer Contact Name *';
  }

  get orderLoad(): string {
    return this.internalNcr ? 'Order/Load #' : 'Customer Order/Load #';
  }

  get contactPhoneLabel(): string {
    return this.internalNcr
      ? 'Contact Phone Number *'
      : 'Customer Phone Number *';
  }

  private setupValueChanges(): void {
    this.registerForm.get('isInternalNcr')?.valueChanges.subscribe((value) => {
      this.internalNcr = value;
      this.updateCustomerFields(value);
    });
  }

  private checkEditingMode(): void {
    if (this.isEditing) {
      this.internalNcr = this.registerForm.get('isInternalNcr')?.value;
    }
  }

  private updateCustomerFields(isInternalNcr: boolean) {
    if (isInternalNcr && !this.isEditing) {
      this.registerForm.patchValue({
        customerName: this.authService.user.name || '',
        customerEmail: this.authService.user.email || '',
      });
    }
    if (!isInternalNcr && !this.isEditing) {
      this.registerForm.patchValue({
        customerName: '',
        customerEmail: '',
      });
    }
  }

  configureActionsBar() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.cancelForm(),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'block',
          buttonStyle: ButtonStyle.SECONDARY,
          btnDisabled: this.loadingSubmit,
        },
        {
          btnClick: () => this.submitForm(),
          btnText: this.isEditing ? 'Save' : 'Submit',
          icon: 'arrow_forward',
          buttonStyle: ButtonStyle.PRIMARY,
          btnId: 'submit',
          isProcessing: this.loadingSubmit,
        },
      ],
    };
  }

  private createBaseNcrObjectFieldsValue() {
    const inputData: NonConformityReport = this.ncrInput || ({} as any);
    this.baseFormValues = {
      id: inputData.id,
      initiatorEmail: (inputData.createdBy && inputData.createdBy.email) || '',
      companyName:
        (inputData.customer && inputData.customer.businessPartnerName) || '',
      companyId: inputData.customerMasterId,
      phoneNumber: (inputData.customer && inputData.customer.phone) || '',
      customerName:
        (inputData.customer && inputData.customer.contactName) || '',
      customerEmail: (inputData.customer && inputData.customer.email) || '',
      orderNumber: (inputData.customer && inputData.customer.orderNumber) || '',
      shipperName: inputData.shipperName || '',

      dateIssued:
        (inputData.problemReportedDateTime &&
          inputData.problemReportedDateTime.dateIssued) ||
        this.now,
      dateDiscovered:
        (inputData.problemReportedDateTime &&
          inputData.problemReportedDateTime.dateDiscovered) ||
        this.now,
      issueDescription: inputData.issueDescription || '',
      issueType: inputData.issueType || '',
      isInternalNcr: inputData.isInternalNcr || false,
      washRequestRelated: {},
      terminal:
        (inputData.qualaInformation &&
          inputData.qualaInformation.terminalName) ||
        '',
      workOrder:
        (inputData.qualaInformation && inputData.qualaInformation.workOrder) ||
        '',
      typeOfWash:
        (inputData.tankInformation && inputData.tankInformation.washType) || '',
      isFoodGradeClean:
        (inputData.tankInformation && inputData.tankInformation.isFoodGrade) ||
        false,
      containerType:
        (inputData.tankInformation && inputData.tankInformation.tankType) || '',
      containerNumber:
        (inputData.tankInformation && inputData.tankInformation.number) || '',

      equipmentOwner: inputData.equipmentOwner || '',
      wasEquipmentDamaged:
        (inputData.damage && inputData.damage.hasBeenDamaged) || false,
      hasTheOwnerBeenNotified: inputData.hasTheOwnerBeenNotified || false,
      whatWasDamaged:
        (inputData.damage && inputData.damage.whatWasDamaged) || '',
      howWasDamaged:
        (inputData.damage && inputData.damage.howItGotDamaged) || '',
      repairCost: (inputData.damage && inputData.damage.repairCost) || 0,
      driverName: (inputData.damage && inputData.damage.driverName) || '',
      whoDidTheRepair:
        (inputData.damage && inputData.damage.whoDidTheRepair) || '',
      receipt: (inputData.damage && inputData.damage.receipt) || false,
      tractorNumber: (inputData.damage && inputData.damage.tractorNumber) || '',
      trailerNumber: (inputData.damage && inputData.damage.trailerNumber) || '',

      shiftStartTime:
        (inputData.peopleInvolved && inputData.peopleInvolved.shiftStartTime) ||
        '',
      cleaner:
        (inputData.peopleInvolved && inputData.peopleInvolved.cleaner) || '',
      facilityManager:
        (inputData.peopleInvolved &&
          inputData.peopleInvolved.facilityManagerName) ||
        '',
      shiftSupervisor:
        (inputData.peopleInvolved &&
          inputData.peopleInvolved.shiftSupervisor) ||
        '',
      technician:
        (inputData.peopleInvolved && inputData.peopleInvolved.technician) || '',
      namesOfPeopleInvolved:
        (inputData.peopleInvolved &&
          inputData.peopleInvolved.namesOfPeopleInvolved.join(', ')) ||
        '',

      peopleStatement: inputData.whoHadSignedStatement || [],
      peopleNotified: inputData.whoHaveBeenNotified || [],
      wasAnyoneInjured:
        inputData.wasAnyoneInjured !== undefined
          ? inputData.wasAnyoneInjured
          : null,
      whoGotHurt: inputData.whoGotHurt || '',
      incidentCategory: inputData.incidentCategory || '',
      correctiveActionCategory: inputData.correctiveActionCategory || '',
      originalInformation: inputData.originalInformation,
      investigation: inputData.investigation,
      fiveWhys: inputData.fiveWhys,
      uploadedFiles: inputData.uploadedFiles || [],
      terminalInfo: {},
    };
    if (inputData.customerMasterId) {
      this.baseFormValues.customerMasterId = inputData.customerMasterId;
    }
  }

  private ensureCompanyNotListed() {
    if (
      this.masterAccounts &&
      this.masterAccounts.length !== 0 &&
      !this.masterAccounts.some(
        (account) => account.customerMasterName === 'Company Not Listed'
      )
    ) {
      this.masterAccounts.push({ customerMasterName: 'Company Not Listed' });
    }
  }

  private subscribeToMasterAccounts() {
    this.masterAccountsSubscription =
      this.masterAccountService.masterAccounts$.subscribe((masterCustomers) => {
        this.masterAccounts = masterCustomers;
        this.ensureCompanyNotListed();
      });
  }

  private checkIfUserIsDispatcherWithCustomerId() {
    return !!(
      this.authService.user &&
      this.authService.hasDispatcherRole() &&
      this.authService.user.customerMasterId
    );
  }

  private subscribeToLoadingStatus() {
    this.isLoadingBPsSubscription =
      this.masterAccountService.isLoadingBPs$.subscribe((status) => {
        this.isLoadingBPs = status;
        this.setAutoSelectedAccount();
      });
  }

  private setAutoSelectedAccount() {
    if (this.isLoadingBPs) {
      return;
    }

    if (this.isDispatcherWithCustomerId) {
      this.setCompanyNameForCustomer();
    } else if (this.isDispatcher) {
      this.setCompanyNameAsNotListed();
    }
  }

  private setCompanyNameForCustomer() {
    const customerMasterId = this.authService.user.customerMasterId;
    const selectedAccount = this.masterAccounts.find(
      (account) => Number(account.customerMasterId) === customerMasterId
    );

    if (selectedAccount) {
      this.registerForm.controls['companyName'].setValue(
        selectedAccount.customerMasterName
      );
    } else {
      this.setCompanyNameAsNotListed();
    }
  }

  private setCompanyNameAsNotListed() {
    this.registerForm.controls['companyName'].setValue(
      this.authService.user.businessPartnerName
    );
  }

  private disableFieldsFromEdit() {
    if (
      this.ncrInput &&
      this.ncrInput.status === NonConformityReportStatus.Completed
    ) {
      Object.keys(this.registerForm.controls).forEach((key) => {
        this.registerForm.get(key).disable();
      });
    }
  }

  private getMasterId(companyName: string): number {
    const customer = this.ncrInput?.customer;
    const isMatchingCompanyName = customer?.businessPartnerName === companyName;

    if (isMatchingCompanyName) {
      return this.ncrInput.customerMasterId;
    }

    return this.masterAccountService.dataStore.masterAccounts.find(
      (bp) => bp.customerMasterName === companyName
    )?.customerMasterId;
  }

  private setEquipmentOwnerLabel() {
    if (this.isQualaWorker) {
      this.equipmentOwnerLabel = 'Who owns the Equipment';
    } else {
      this.equipmentOwnerLabel = 'Who owns the Equipment *';
    }
  }

  private retrieveWhoGotInjuredRequirementStatus(wasAnyoneInjured: boolean) {
    if (wasAnyoneInjured) {
      this.registerForm.controls.whoGotHurt.setValidators(Validators.required);
    } else {
      this.registerForm.controls.whoGotHurt.setValidators([]);
    }
    this.registerForm.controls.whoGotHurt.updateValueAndValidity();
  }

  private createFormGroup() {
    this.createBaseNcrObjectFieldsValue();

    const ncrFormControlsData =
      this.ncrMasterFormServices.getFormObjectAndControls(
        this.baseFormValues,
        this.isQualaWorker,
        this.isUnauthenticated,
        this.isEditing
      );
    this.registerForm = new UntypedFormGroup(
      {
        ...Object.keys(ncrFormControlsData)
          .filter((key) => ncrFormControlsData[key].isVisible)
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: ncrFormControlsData[key].control,
            }),
            {}
          ),
      },
      {
        validators: this.damageFormValidation() as ValidatorFn,
      }
    );
    this.registerForm.controls.wasAnyoneInjured.valueChanges.subscribe(
      (value) => {
        this.retrieveWhoGotInjuredRequirementStatus(value);
      }
    );
  }

  private damageFormValidation(): ValidatorFn {
    return (group: UntypedFormGroup) => {
      const hasDamage = group.controls.wasEquipmentDamaged.value;
      const validationsApplied = this.isQualaWorker;

      if (!validationsApplied) {
        return null;
      }

      const requiredControls = [
        group.controls.whatWasDamaged,
        group.controls.howWasDamaged,
        group.controls.tractorNumber,
        group.controls.trailerNumber,
        group.controls.repairCost,
        group.controls.receipt,
        group.controls.driverName,
        group.controls.equipmentOwner,
      ];

      if (!hasDamage) {
        // Ignore all errors
        requiredControls.forEach((control) => control.setErrors(null));
        return null;
      }

      return group.errors;
    };
  }

  private getSafe(func: () => any) {
    try {
      return func();
    } catch (e) {
      return undefined;
    }
  }

  private getInvestigationData(): {
    investigation: Investigation;
    remedialTrainingProvided: string;
    incidentResponsibility: string;
  } {
    const now = Math.floor(new Date().getTime() / 1000.0);
    const getFormControlValueAndUpdate = (
      actionFormControl: AbstractControl,
      responsibleFormControl: AbstractControl,
      completionDateFormControl: AbstractControl,
      originalDate?: number,
      defaultResponsible?: string
    ) => {
      return {
        description: actionFormControl.value,
        responsible: defaultResponsible || responsibleFormControl.value,
        completionDate: completionDateFormControl.value,
        date:
          actionFormControl.touched || responsibleFormControl.touched
            ? now
            : originalDate,
      };
    };

    const investigationForm = this.registerForm.controls
      .investigation as UntypedFormGroup;
    const responsible = investigationForm.value.actionsResponsible;

    return {
      investigation: {
        dateLastUpdate: now,
        responsible: {
          name: responsible,
          sameForAllActions: investigationForm.value.sameResponsible,
        },
        immediateAction: getFormControlValueAndUpdate(
          investigationForm.controls.immediateActionsNeeds,
          investigationForm.controls.immediateActionsNeedsResponsible,
          investigationForm.controls.immediateActionsNeedsCompletionDate,
          this.getSafe(() => this.ncrInput.investigation.immediateAction.date),
          investigationForm.value.sameResponsible && responsible
        ),
        correctiveAction: getFormControlValueAndUpdate(
          investigationForm.controls.correctiveAction,
          investigationForm.controls.correctiveActionResponsible,
          investigationForm.controls.correctiveActionCompletionDate,
          this.getSafe(() => this.ncrInput.investigation.correctiveAction.date),
          investigationForm.value.sameResponsible && responsible
        ),
        validationCorrectiveAction: getFormControlValueAndUpdate(
          investigationForm.controls.validationCorrectiveAction,
          investigationForm.controls.validationCorrectiveActionResponsible,
          investigationForm.controls.validationCorrectiveActionCompletionDate,
          this.getSafe(
            () => this.ncrInput.investigation.validationCorrectiveAction.date
          ),
          investigationForm.value.sameResponsible && responsible
        ),
      },
      remedialTrainingProvided:
        investigationForm.value.remedialTrainingProvided,
      incidentResponsibility: investigationForm.value.incidentResponsibility,
    };
  }

  private getReviewData(): {
    incidentResponsibility: string;
    correctiveActionCategory: string;
    incidentCategory: string;
  } {
    const reviewForm = this.registerForm.controls.review as UntypedFormGroup;

    return {
      correctiveActionCategory: reviewForm.value.correctiveActionCategory,
      incidentCategory: reviewForm.value.incidentCategory,
      incidentResponsibility: reviewForm.value.incidentResponsibility,
    };
  }

  private getFiveWhyData(responsibleInvestigation): {
    fiveWhys: FiveWhys;
  } {
    const now = Math.floor(new Date().getTime() / 1000.0);
    const getFormControlValueAndUpdate = (
      actionFormControl: AbstractControl,
      responsibleFormControl: AbstractControl,
      completionDateFormControl: AbstractControl,
      originalDate?: number,
      defaultResponsible?: string
    ) => {
      return {
        description: actionFormControl.value,
        responsible: defaultResponsible || responsibleFormControl.value,
        completionDate: completionDateFormControl.value,
        date:
          actionFormControl.touched || responsibleFormControl.touched
            ? now
            : originalDate,
      };
    };

    const fiveWhyForm = this.registerForm.controls.fiveWhys as UntypedFormGroup;

    return {
      fiveWhys: {
        firstWhy: fiveWhyForm.value.firstWhy,
        secondWhy: fiveWhyForm.value.secondWhy,
        thirdWhy: fiveWhyForm.value.thirdWhy,
        fourthWhy: fiveWhyForm.value.fourthWhy,
        fifthWhy: fiveWhyForm.value.fifthWhy,
        rootCause: getFormControlValueAndUpdate(
          fiveWhyForm.controls.rootCause,
          fiveWhyForm.controls.rootCauseResponsible,
          fiveWhyForm.controls.rootCauseCompletionDate,
          this.getSafe(() => this.ncrInput.fiveWhys.rootCause.date),
          responsibleInvestigation.sameForAllActions &&
            responsibleInvestigation.name
        ),
      },
    };
  }

  private getCompanyName(formData: any): string {
    const internalPrefix = 'Internal - ';
    const companyNotListed = 'Company Not Listed';
    if (this.internalNcr && formData.customerName) {
      return `${internalPrefix}${formData.customerName}`;
    }

    const isCompanyNotListed =
      !formData.companyId && !this.ncrInput?.customer?.businessPartnerName;

    return isCompanyNotListed ? companyNotListed : formData.companyName;
  }

  private buildResultNcr(): NcrFormObject {
    const formData: NcrFormObject = {
      ...this.registerForm.value,
      id: this.ncrInput && this.ncrInput.id,
      originalInformation: this.baseFormValues.originalInformation,
      uploadedFiles: this.baseFormValues.uploadedFiles,
    };

    if (!formData.isInternalNcr) {
      formData.companyId = Number(
        this.getMasterId(this.registerForm.controls.companyName.value)
      );
    }

    formData.companyName = this.getCompanyName(formData);

    if (this.ncrInput && this.ncrInput.investigation) {
      formData.investigation = this.ncrInput.investigation;
    }

    if (this.ncrInput && this.ncrInput.fiveWhys) {
      formData.fiveWhys = this.ncrInput.fiveWhys;
    }

    const additionalData = {
      terminalInfo:
        this.warehousesMap[this.registerForm.controls.terminal.value],
    };

    if (
      this.ncrInput &&
      this.registerForm.controls.fiveWhys &&
      this.registerForm.controls.investigation &&
      (this.registerForm.controls.investigation.touched ||
        this.registerForm.controls.fiveWhys.touched)
    ) {
      const investigationFormData = this.getInvestigationData();
      const responsible = investigationFormData.investigation.responsible;
      const fiveWhyFormData = this.getFiveWhyData(responsible);

      if (
        fiveWhyFormData.fiveWhys.rootCause.description &&
        !fiveWhyFormData.fiveWhys.rootCause.date
      ) {
        fiveWhyFormData.fiveWhys.rootCause.date = this.now;
      }

      const reviewFormData = this.registerForm.controls.review
        ? this.getReviewData()
        : {};

      return {
        ...formData,
        ...additionalData,
        ...fiveWhyFormData,
        ...investigationFormData,
        ...(this.registerForm.controls.review && reviewFormData),
      };
    }

    return { ...formData, ...additionalData };
  }

  getFormValidationErrors() {
    const invalidFields = [];
    Object.keys(this.registerForm.controls).forEach((key) => {
      const controlErrors: ValidationErrors =
        this.registerForm.get(key)?.errors;

      if (
        key === 'terminal' &&
        !this.warehousesMap[this.registerForm.controls[key].value]
      ) {
        this.registerForm.controls[key].setErrors({ required: true });
        invalidFields.push(NCRCreateFieldsEnum[key]);
      }

      if (controlErrors != null) {
        if (key === 'companyName' && this.internalNcr) {
          return;
        }

        Object.keys(controlErrors).forEach((keyError) => {
          invalidFields.push(NCRCreateFieldsEnum[key]);
        });
      }
    });
    return invalidFields;
  }

  getValidationMessage(invalidFields) {
    if (invalidFields.length === 1) {
      return `Please enter: ${invalidFields[0]}`;
    }
    const firsts = invalidFields.slice(0, invalidFields.length - 1);
    const last = invalidFields[invalidFields.length - 1];
    return `Please enter: ${firsts.join(', ')} and ${last}`;
  }

  showMessages(invalidFields: string[]) {
    if (invalidFields.includes(NCRCreateFieldsEnum.peopleStatement)) {
      this.showPeopleStatementValidationMessage = true;
    }

    if (invalidFields.includes(NCRCreateFieldsEnum.peopleNotified)) {
      this.showPeopleNotifiedValidationMessage = true;
    }
  }

  submitForm = () => {
    this.triedSubmit = true;

    this.displayValidationMessage = false;
    this.showPeopleStatementValidationMessage = false;
    this.showPeopleNotifiedValidationMessage = false;
    const invalidFields = this.getFormValidationErrors();

    if (invalidFields.length > 0) {
      this.validationMessage = this.getValidationMessage(invalidFields);
      this.showMessages(invalidFields);
      this.displayValidationMessage = true;
      return;
    }

    const formData = this.buildResultNcr();
    this.ncrSubmitEvent.emit({ data: formData });
  };

  cancelForm = () => {
    this.ncrCancelEvent.emit({ formTouched: this.registerForm.touched });
  };

  searchWO() {
    this.loadingWorkOrder = true;
    this.getDataFromWorkOrder()
      .then((workOrderData: DataFromWorkOrder) => {
        const slicePosition = workOrderData.containerName.indexOf('-');
        const containerNumber = workOrderData.containerName
          .slice(0, slicePosition - 1)
          .trim();
        const containerType = workOrderData.containerName
          .slice(slicePosition + 1)
          .trim();
        this.registerForm.controls.workOrder.setValue(
          this.registerForm.controls.workOrder.value.toUpperCase()
        );
        /*tslint:disable: no-unused-expression */
        this.registerForm.controls.containerType &&
          this.registerForm.controls.containerType.setValue(containerType);
        this.registerForm.controls.containerNumber &&
          this.registerForm.controls.containerNumber.setValue(containerNumber);
        this.registerForm.controls.typeOfWash &&
          this.registerForm.controls.typeOfWash.setValue(
            workOrderData.planName
          );
        this.registerForm.controls.equipmentOwner &&
          this.registerForm.controls.equipmentOwner.setValue(
            workOrderData.containerOwnerName
          );
        const formattedWarehouse = this.searchFormattedWarehouse(
          workOrderData.terminalName
        );
        this.registerForm.controls.terminal.setValue(formattedWarehouse);
        this.registerForm.controls.companyName.setValue(
          workOrderData.masterCustomerName || workOrderData.businessPartnerName
        );

        this.baseFormValues.originalInformation = {
          terminalId: workOrderData.terminalId,
          containerId: workOrderData.containerId,
          containerOwnerName: workOrderData.containerOwnerName,
          containerOwnerId: workOrderData.containerOwnerId,
          containerName: workOrderData.containerName,
          businessPartnerName: workOrderData.businessPartnerName,
          planId: workOrderData.planId,
          planName: workOrderData.planName,
          terminalName: workOrderData.terminalName,
        };
        if (workOrderData.masterCustomerId) {
          this.baseFormValues.originalInformation.customerMasterId =
            workOrderData.masterCustomerId;
        }
        this.baseFormValues.qualaInformation.workOrder =
          workOrderData.workOrder;
      })
      .catch((error) => {
        if (error.response.status === 404) {
          this.toastr.error('', 'Work order not found');
        } else {
          this.toastr.error('', 'Error getting the Work Order number.');
        }
      })
      .finally(() => (this.loadingWorkOrder = false));
  }

  getTerminalNumber(terminal: string) {
    return terminal.split('-').shift();
  }

  private searchFormattedWarehouse(
    terminalToSearch: string
  ): string | undefined {
    const separator = ' - ';
    const terminalNumberOfWO = this.getTerminalNumber(terminalToSearch).trim();

    const foundEntry = Object.entries(this.warehousesMap).find(
      ([key, item]) => item.terminalNumber === terminalNumberOfWO
    );

    if (!foundEntry) {
      return;
    }

    const [key, warehouse] = foundEntry;
    const formattedNameParts = [
      warehouse.terminalNumberDisplay,
      warehouse.terminalNameDisplay,
    ];
    const terminalDisplay = formattedNameParts.join(separator);
    const formattedWarehouse = this.formattedWarehouses.find((terminal) =>
      terminal.includes(terminalDisplay)
    );

    return formattedWarehouse;
  }

  async getDataFromWorkOrder() {
    const upperWorkOrder =
      this.registerForm.controls.workOrder.value.toUpperCase();
    const data =
      await this.ncrService.getInformationFromWorkOrder(upperWorkOrder);
    return data;
  }
}
