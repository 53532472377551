import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationService } from '../navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrl: './toolbar.component.scss',
})
export class ToolbarComponent implements OnInit {
  showRolePicker: boolean = false;

  @Input() userName: string;
  @Input() isMobile: boolean;
  @Input() hasQualaRole: boolean;

  @Output() toggleSubnav = new EventEmitter();

  constructor(
    private router: Router,
    private menuService: NavigationService
  ) {}

  ngOnInit(): void {
    this.showRolePicker = this.menuService.hasRolePicker(this.router.url);
    this.router.events.subscribe(() => {
      this.showRolePicker = this.menuService.hasRolePicker(this.router.url);
    });
  }

  emitToggle(): void {
    this.toggleSubnav.emit();
  }
}
