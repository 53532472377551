import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';

@Component({
  selector: 'app-wash-reject-with-heel',
  templateUrl: './wash-reject-with-heel.component.html',
  styleUrls: ['./wash-reject-with-heel.component.scss'],
})
export class WashRejectWithHeelComponent implements OnInit, OnDestroy {
  rejectForm = this.fb.group({
    heelAmount: [null, Validators.required],
  });
  actionsBarConfig: ActionsBarProperties;
  formStatusChangesSubscription: Subscription;

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<WashRejectWithHeelComponent>
  ) {}

  get code() {
    return 'HEELFOUND';
  }

  get displayName() {
    return 'Request Rejected - Heel Found';
  }

  ngOnDestroy(): void {
    this.formStatusChangesSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.cancelDialog(),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
        },
        {
          btnClick: () => this.submitDialog(),
          btnId: 'reject',
          btnText: 'Reject',
          buttonStyle: ButtonStyle.PRIMARY,
          btnDisabled: !this.rejectForm.valid,
        },
      ],
    };
    this.formStatusChangesSubscription =
      this.rejectForm.statusChanges.subscribe((status) => {
        this.actionsBarConfig.buttons[1].btnDisabled = status !== 'VALID';
      });
  }

  cancelDialog = () => {
    this.dialogRef.close();
  };

  submitDialog = () => {
    if (!this.rejectForm.valid) {
      return;
    }

    const heelAmount = this.rejectForm.get('heelAmount').value;
    const code = this.code;
    const label = this.displayName;
    this.dialogRef.close({ heelAmount, code, label });
  };
}
