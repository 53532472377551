import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';

import {
  MenuConfig,
  MenuOption,
  OptionConfig,
  SectionConfig,
} from '../navigation.model';
import { NavigationService } from '../navigation.service';
import { SubNavOptionComponent } from './subnav-option/subnav-option.component';
import { WashListService } from 'src/app/dashboard/wash-list/wash-list.service';
import { AuthService } from 'src/app/core/auth/auth.service';

@Component({
  selector: 'app-subnav',
  standalone: true,
  imports: [SubNavOptionComponent],
  templateUrl: './subnav.component.html',
  styleUrl: './subnav.component.scss',
})
export class SubNavComponent implements OnInit, AfterViewChecked, OnChanges {
  private isTabActive = true;
  roleCountIntervalId = null;

  @Input() isMobile: boolean;
  @Input() isVisible: boolean;
  @Input() isDesktop: boolean;
  @Input() isPortrait: boolean;
  @Input() menuConfig: MenuConfig;
  @Input() sectionSelected: string;
  @Input() isDesktopExpanded: boolean;
  @Input() hasQualaRole: () => boolean;
  @Input() hasSubnavPermission: boolean;

  @Output() itemChosen = new EventEmitter<
    SectionConfig | OptionConfig | string
  >();

  currentSubSystemSection: SectionConfig | OptionConfig | undefined;

  constructor(
    private authService: AuthService,
    private menuService: NavigationService,
    private washListService: WashListService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.sectionSelected || changes.menuConfig) {
      if (this.sectionSelected) {
        this.currentSubSystemSection = this.menuConfig.sections.find(
          (section) => section?.value === this.sectionSelected
        );
      } else {
        this.menuConfiguration();
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.menuConfiguration();

    window.onblur = () => {
      this.isTabActive = false;
    };
    window.onfocus = () => {
      this.isTabActive = true;
    };
    this.initializeUserRoleItemCounts();

    this.authService.user$.subscribe((user) => {
      if (user) this.refetchDynamicContent();
    });

    this.menuConfig.sections.forEach((section) => {
      if (section?.value === 'wash-list') {
        this.washListService.countMap.subscribe((countMap) => {
          section.options.forEach((subItem) => {
            if (countMap.total[subItem?.value] !== undefined) {
              subItem.totalCount = countMap.total[subItem?.value];
            }
            if (countMap.new[subItem?.value] !== undefined) {
              subItem.newCount = countMap.new[subItem?.value];
            }
          });
        });
      }
    });
  }

  onItemChosen($event: SectionConfig | OptionConfig): void {
    this.itemChosen.emit($event);
  }

  getSectionLabel() {
    return this.currentSubSystemSection?.label?.toUpperCase();
  }

  showMinimalistSubnav(): boolean {
    return !this.isDesktopExpanded;
  }

  private menuConfiguration(): void {
    const currentSubsystem = this.menuService.getMenuSectionThroughUrl(
      this.menuConfig
    );
    this.sectionSelected = currentSubsystem?.value;
    this.currentSubSystemSection = currentSubsystem;
  }

  private initializeUserRoleItemCounts() {
    this.authService.isAuthorized().then((isAuthenticated) => {
      if (isAuthenticated && this.currentSubSystemSection?.options?.length) {
        const oneMinuteInMilliseconds = 60000;
        this.getAllWashItemsCountForUserRole();
        this.roleCountIntervalId = setInterval(() => {
          this.getAllWashItemsCountForUserRole();
        }, oneMinuteInMilliseconds * 3);
      }
    });
  }

  private refetchDynamicContent() {
    if (this.roleCountIntervalId) {
      clearInterval(this.roleCountIntervalId);
      this.roleCountIntervalId = null;
    }
    this.initializeUserRoleItemCounts();
  }

  getAllWashItemsCountForUserRole() {
    if (this.hasQualaRole) {
      this.washListService.refreshCount([
        MenuOption.Draft,
        MenuOption.NewWashRequests,
        MenuOption.Pending,
        MenuOption.Accepted,
        MenuOption.NeedsAction,
        MenuOption.InProgress,
        MenuOption.Hold,
        MenuOption.Completed,
        MenuOption.Canceled,
        MenuOption.Rejected,
        MenuOption.CreditHold,
        MenuOption.Expired,
        MenuOption.SchneiderPortalCompletions,
      ]);
    } else {
      this.washListService.refreshCount([
        MenuOption.Draft,
        MenuOption.Submitted,
        MenuOption.NeedsAction,
        MenuOption.Accepted,
        MenuOption.Pending,
        MenuOption.InProgress,
        MenuOption.Completed,
        MenuOption.Canceled,
        MenuOption.Rejected,
      ]);
    }
  }

  getImportantItemsCountForQualaRole() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.NewWashRequests,
      MenuOption.Pending,
      MenuOption.NeedsAction,
    ]);
  }

  getNormalItemsCountForQualaRole() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Accepted,
      MenuOption.InProgress,
      MenuOption.Hold,
      MenuOption.CreditHold,
    ]);
  }

  getImportantItemsCountForDispatcher() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Submitted,
      MenuOption.Accepted,
    ]);
  }

  getNormalItemsCountForDispatcher() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.NeedsAction,
      MenuOption.Pending,
      MenuOption.InProgress,
    ]);
  }

  getLowPriorityItemsCount() {
    if (!this.isTabActive) {
      return;
    }
    this.washListService.refreshCount([
      MenuOption.Draft,
      MenuOption.Completed,
      MenuOption.Canceled,
      MenuOption.Rejected,
    ]);
  }
}
