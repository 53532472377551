import { Component } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { MenuOption } from '../../../shared/navigation/navigation.model';

@Component({
  selector: 'app-data-fabric',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './data-fabric.component.html',
  styleUrl: './data-fabric.component.scss',
})
export class DataFabricComponent {
  static route = `other-tools/${MenuOption.OtherToolsDataFabric}`;
}
