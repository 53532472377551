import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import { NcrService } from '../ncr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogConfirmationService } from '../../../shared/dialog-confirmation/dialog-confirmation.service';
import { NonConformityReport } from '../non-conformity-report.model';
import { MasterFormOutput } from '../ncr-master-form/ncr-master-form.component';
import { MenuOption } from '../../../shared/navigation/navigation.model';

@Component({
  selector: 'app-ncr-creation',
  templateUrl: './internal-ncr-creation.component.html',
  styleUrls: ['./internal-ncr-creation.component.scss'],
})
export class InternalNcrCreationComponent implements OnInit {
  isQualaWorker = this.authService.hasQualaWorkerRole();
  isRVP = this.authService.hasFullReportAccess();
  loadingSubmit = false;

  private newReport: NonConformityReport;
  private option: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private dialogConfirmationService: DialogConfirmationService,
    private authService: AuthService,
    private toastr: ToastrService,
    private ncrService: NcrService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe((params) => {
      this.option = params.get('option') || 'opened';
    });
  }

  checkBusinessPartnerName(data): string {
    if (data.isInternalNcr) {
      return `Internal - ${data.customerName}`;
    }
    return data.companyName;
  }

  createReport({ data, error }: MasterFormOutput) {
    if (!!error) {
      this.toastr.error('Error trying to submit the non conformity report.');
      this.router.navigate([`../list/${this.option}`], {
        relativeTo: this.activatedRoute,
      });
    }

    this.loadingSubmit = true;
    this.newReport = {
      madeByQualaWorker: this.isQualaWorker,
      createdBy: {
        email: data.initiatorEmail,
        userBusinessPartnerId: this.authService.user.businessPartnerId,
        userCustomerMasterId: this.authService.user.customerMasterId,
      },
      customer: {
        businessPartnerName: this.checkBusinessPartnerName(data),
        email: data.customerEmail,
        contactName: data.customerName,
        phone: data.phoneNumber,
        orderNumber: data.orderNumber,
      },
      problemReportedDateTime: {
        dateDiscovered: data.dateDiscovered,
        dateIssued: data.dateIssued,
      },
      qualaInformation: {
        terminalName: data.terminal,
        terminalNumber: data.terminalInfo.terminalNumber,
        workOrder: data.workOrder,
      },
      peopleInvolved: {
        cleaner: data.cleaner,
        facilityManagerName: data.facilityManager,
        shiftSupervisor: data.shiftSupervisor,
        shiftStartTime: data.shiftStartTime,
        technician: data.technician,
        namesOfPeopleInvolved:
          (data.namesOfPeopleInvolved &&
            data.namesOfPeopleInvolved.trim().split(',')) ||
          [],
      },
      tankInformation: {
        isFoodGrade: data.isFoodGradeClean,
        number: data.containerNumber,
        tankType: data.containerType,
        washType: data.typeOfWash,
      },
      issueDescription: data.issueDescription,
      issueType: data.issueType,
      isInternalNcr: data.isInternalNcr,
      equipmentOwner: data.equipmentOwner,
      damage: {
        hasBeenDamaged: data.wasEquipmentDamaged,
        whatWasDamaged: data.whatWasDamaged,
        howItGotDamaged: data.howWasDamaged,
        repairCost: data.repairCost,
        driverName: data.driverName,
        whoDidTheRepair: data.whoDidTheRepair,
        receipt: data.receipt,
        tractorNumber: data.tractorNumber,
        trailerNumber: data.trailerNumber,
      },
      wasAnyoneInjured: data.wasAnyoneInjured,
      shipperName: data.shipperName,
      whoGotHurt: data.whoGotHurt,
      whoHadSignedStatement: data.peopleStatement,
      whoHaveBeenNotified: data.peopleNotified,
      hasTheOwnerBeenNotified: data.hasTheOwnerBeenNotified,
      uploadedFiles: data.uploadedFiles,
      originalInformation: data.originalInformation,
    };

    if (data.companyId) {
      this.newReport.customerMasterId = data.companyId;
    }

    this.ncrService
      .createNCR(this.newReport)
      .then(() => {
        this.toastr.success('', 'Non conformity report submitted!');
        if (this.isRVP || !this.isQualaWorker) {
          this.router.navigate([`../list/${MenuOption.NcrOpened}`], {
            relativeTo: this.activatedRoute,
          });
        } else {
          this.router.navigate([`../list/${this.option}`], {
            relativeTo: this.activatedRoute,
          });
        }
      })
      .catch(() => {
        this.toastr.error('Error trying to submit the non conformity report.');
      })
      .finally(() => {
        this.loadingSubmit = false;
      });
  }

  cancelCreation() {
    this.dialogConfirmationService
      .openDialog({
        title: 'Do you really want cancel the report creation?',
        icon: 'check_circle',
        action: 'Yes',
      })
      .then((dialogResult) => {
        if (dialogResult) {
          this.router.navigate([`../list/${this.option}`], {
            relativeTo: this.activatedRoute,
          });
        }
      });
  }
}
