import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import {
  ReducedNonConformityReport,
  NonConformityReportStatus,
} from '../non-conformity-report.model';
import { MenuOption, Title } from '../../../shared/navigation/navigation.model';
import { NcrService } from '../ncr.service';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/core/auth/auth.service';
import { APIService } from 'src/app/core/API.service';
import { FormControl, UntypedFormControl } from '@angular/forms';
import {
  ElasticSearch,
  Filter,
  QueryName,
  Sort,
} from 'src/app/core/model/search-definition.model';
import { pageSize } from 'src/app/core/elasticsearch.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-ncr-list',
  templateUrl: './ncr-list.component.html',
  styleUrls: ['./ncr-list.component.scss'],
})
export class NcrListComponent implements OnInit, OnDestroy {
  static route = 'non-conformity-report';

  columns = [
    {
      label: 'Container Number',
      fieldName: 'tankInformation.number',
      fieldAlias: 'tankNumber',
      sortWithKeyword: true,
    },
    {
      label: 'Terminal',
      fieldName: 'qualaInformation.terminalName',
      fieldAlias: 'terminalName',
      sortWithKeyword: true,
    },
    {
      label: 'Customer',
      fieldName: 'customer.businessPartnerName',
      fieldAlias: 'businessPartnerName',
      sortWithKeyword: true,
      focus: true,
    },
    {
      label: 'Issue Type',
      fieldName: 'issueType',
      fieldAlias: 'issueType',
      sortWithKeyword: true,
    },
    {
      label: 'Date issued',
      fieldName: 'problemReportedDateTime.dateIssued',
      dateTimeConfig: {
        format: 'MM-dd-yyyy',
        timezone: 'UTC',
      },
      fieldAlias: 'dateIssued',
    },
    {
      label: 'Due Date',
      fieldName: 'dueDate',
      dateTimeConfig: {
        format: 'MM-dd-yyyy',
        timezone: 'UTC',
      },
      fieldAlias: 'dueDate',
    },
  ];

  private filteredFields = [
    'issueType',
    'tankInformation.number',
    'customer.businessPartnerName',
    'qualaInformation.terminalName',
  ];

  private reducedNonConformityReports$: Subscription;
  hasQualaWorkerRole: boolean;
  rawReducedNonConformityReports: ReducedNonConformityReport[] = [];
  filteredReducedNonConformityReports: ReducedNonConformityReport[] = [];
  isLoadingScreen: boolean;
  title: string;
  option: MenuOption;
  currentStatus: string;
  ncrStatus: string;
  today: number = Math.round(Date.now() / 1000);
  issueTypes = [];
  onlyMine: FormControl;
  ascSort: boolean;
  sortBy: string;
  filter: UntypedFormControl;
  elasticSearch: ElasticSearch;
  isLoadingList: boolean;
  item: any;
  rowHeaderData: any[];
  expandedItemId: string;

  constructor(
    private route: ActivatedRoute,
    private gtmService: GoogleTagManagerService,
    private authService: AuthService,
    private ncrService: NcrService,
    private apiService: APIService
  ) {}

  handleShowDetailsEvent(item: any) {
    this.expandedItemId = item.id;
  }

  private initSort() {
    if (this.authService.hasQualaWorkerRole()) {
      this.ascSort = true;
      this.sortBy = 'customer.businessPartnerName.keyword';
    } else {
      this.ascSort = null;
      this.sortBy = null;
    }
  }

  private getSort() {
    if (typeof this.ascSort !== 'undefined' && this.ascSort !== null) {
      const sortOrder = this.ascSort ? 'asc' : 'desc';
      const sort = new Sort(this.sortBy, sortOrder);
      return sort;
    }
    return null;
  }

  private trackGtmTagFilter(value) {
    const view = this.filteredReducedNonConformityReports.length;
    const page = pageSize > view ? 0 : Math.ceil(view / pageSize) - 1;

    const sort = this.getSort()
      ? `&sort=${this.getSort().field.split('.')[0]}`
      : '';
    let gtmTag;

    if (value) {
      gtmTag = {
        event: 'pageview',
        dp: `${window.location.pathname}/?q=${value}${sort}&page=${page}&view=${view}`,
        dh: window.location.host,
      };
    }

    this.gtmService.pushTag(gtmTag);
  }

  ngOnInit(): void {
    this.reducedNonConformityReports$ =
      this.ncrService.nonConformityReports.subscribe((items) => {
        this.rawReducedNonConformityReports = items;
        if (this.authService.hasDispatcherRole()) {
          this.filteredReducedNonConformityReports = items.filter(
            (item) => !item.resolutionToBeDetermined
          );
        } else {
          this.filteredReducedNonConformityReports = items;
        }
        this.isLoadingScreen = false;
        const resolutionColumn = this.columns.find(
          (column) => column.label === 'Resolution'
        );
        if (this.showResolutionColumn() && !resolutionColumn) {
          this.columns.push({
            label: 'Resolution',
            fieldAlias: 'resolutionToBeDetermined',
            fieldName: 'resolutionToBeDetermined',
            sortWithKeyword: false,
          });
        } else if (!this.showResolutionColumn()) {
          this.columns = this.columns.filter(
            (column) => column.label !== 'Resolution'
          );
        }
        this.mountRowHeaderData();
      });
    this.hasQualaWorkerRole = this.authService.hasQualaWorkerRole();
    this.ncrService.fetchAllManagers().catch((error) => {
      throw error;
    });
    this.route.params.subscribe((params) => {
      this.filteredReducedNonConformityReports = [];
      this.isLoadingScreen = true;
      this.option = params.option;
      this.initSort();
      this.getSessionTitle(this.option);
      this.getCurrentStatus(this.option);
      this.ncrService.getNcrList(this.currentStatus, 0, null, this.getSort());
      this.filter = new UntypedFormControl();
      this.filter.valueChanges.subscribe((value) => {
        const filter = new Filter(
          this.filteredFields,
          value,
          undefined,
          QueryName.simpleQueryString,
          true
        );
        this.ncrService
          .getNcrList(this.currentStatus, 0, filter, this.getSort())
          .finally(() => {
            this.isLoadingList = false;
          });
        this.trackGtmTagFilter(value);
      });
    });

    this.loadInitialData();

    this.onlyMine = new FormControl(false);
    const hasAuthenticatedUserId =
      this.authService && this.authService.user && this.authService.user.id;
    this.onlyMine.valueChanges.pipe().subscribe((value) => {
      const showOnlyMine = this.authService.hasDispatcherRole() && value;
      if (showOnlyMine) {
        this.filteredReducedNonConformityReports =
          this.rawReducedNonConformityReports
            .filter((item) => !item.resolutionToBeDetermined)
            .filter((item) => {
              const hasNcrCreatedById = item.createdBy && item.createdBy.id;
              return (
                hasNcrCreatedById &&
                hasAuthenticatedUserId &&
                item.createdBy.id === this.authService.user.id
              );
            });
      } else {
        this.filteredReducedNonConformityReports =
          this.rawReducedNonConformityReports.filter(
            (item) => !item.resolutionToBeDetermined
          );
      }
    });
  }

  capitalizeAndReplaceUnderscores(input: string) {
    return input
      .replace(/_/g, ' ')
      .split(' ')
      .map((word, index) =>
        index === 0
          ? word.toLowerCase().charAt(0).toUpperCase() +
            word.toLowerCase().slice(1)
          : word.toLowerCase()
      )
      .join(' ');
  }

  private mountRowHeaderData() {
    this.rowHeaderData = [];
    this.filteredReducedNonConformityReports.forEach((ncr) => {
      const createdByIntegrations = ncr.createdByDataFabric;
      const ncrHeaderInfo: any = {
        rowToolTip: '',
      };
      for (const [key, value] of Object.entries(ncr)) {
        if (key === 'tankInformation') {
          ncrHeaderInfo.tankNumber = {
            label: 'Container Number',
            value: value.number,
            iconConfig: createdByIntegrations
              ? [
                  {
                    iconName: 'cloud-upload-alt',
                    iconType: this.checkNCRWarning(ncr) ? 'warn' : 'info',
                    iconTooltip: 'Created by integrations',
                  },
                ]
              : undefined,
          };
        } else if (key === 'qualaInformation') {
          ncrHeaderInfo.terminalName = {
            value: value.terminalName,
            label: 'Terminal',
          };
        } else if (key === 'problemReportedDateTime') {
          ncrHeaderInfo.dateIssued = {
            value: value.dateIssued,
            label: 'Date issued',
          };
        } else if (key === 'customer') {
          ncrHeaderInfo.businessPartnerName = {
            value: value.businessPartnerName,
            label: 'Customer',
          };
        } else if (key === 'issueType') {
          ncrHeaderInfo.issueType = {
            value: this.capitalizeAndReplaceUnderscores(value),
            label: 'Issue Type',
          };
        } else if (key === 'dueDate') {
          ncrHeaderInfo.dueDate = {
            value,
            label: 'Due Date',
            iconConfig: this.showIsLateWarning(
              ncr.dueDate,
              this.currentStatus,
              ncr.wasLateCompleted
            )
              ? [
                  {
                    iconName: 'clock',
                    iconType: 'warn',
                  },
                ]
              : undefined,
          };
          ncrHeaderInfo.warnRow = this.showIsLateWarning(
            ncr.dueDate,
            this.currentStatus,
            ncr.wasLateCompleted
          );
          ncrHeaderInfo.rowToolTip += this.showIsLateWarning(
            ncr.dueDate,
            this.currentStatus,
            ncr.wasLateCompleted
          )
            ? '\nThis report is late!'
            : '';
        } else if (
          key === 'resolutionToBeDetermined' &&
          this.showResolutionColumn()
        ) {
          ncrHeaderInfo.resolutionToBeDetermined = {
            value: value ? 'TBD' : 'Approved',
            label: 'Resolution',
          };
        }
      }
      if (Object.keys(ncrHeaderInfo).length > 0) {
        this.rowHeaderData.push(ncrHeaderInfo);
      }
    });
  }

  isObjectEmpty(obj: object): boolean {
    if (!obj) {
      return true;
    }
    return Object.keys(obj).length === 0;
  }

  checkNCRWarning(ncr) {
    if (this.option === MenuOption.NcrInAnalysis && ncr.isReassignment) {
      return true;
    }

    if (
      this.isObjectEmpty(ncr.dataFabricExecution) ||
      ncr?.issueFields?.length === 0
    ) {
      return false;
    }
    return true;
  }

  loadInitialData() {
    this.apiService.ListNcrIssueType().then((list) => {
      this.issueTypes = list.items;
    });
  }

  ngOnDestroy() {
    this.reducedNonConformityReports$.unsubscribe();
  }

  showIsLateWarning(
    dueDate: number,
    status: string,
    wasLateCompleted?: boolean
  ) {
    const daysBetween = this.compareDaysBetweenTimestamps(this.today, dueDate);

    if (
      (daysBetween > 0 && status !== 'COMPLETED' && status !== 'CANCELLED') ||
      wasLateCompleted
    ) {
      return true;
    } else {
      return false;
    }
  }

  compareDaysBetweenTimestamps(
    startTimestamp: number,
    finishTimestamp: number
  ) {
    const startDate = new Date(startTimestamp * 1000).getTime();
    const finishDate = new Date(finishTimestamp * 1000).getTime();

    const dayTime = 1000 * 60 * 60 * 24;
    const diffTime = startDate - finishDate;
    const daysBetween = Math.floor(diffTime / dayTime);

    return daysBetween;
  }

  showResolutionColumn() {
    return this.currentStatus === 'COMPLETED';
  }

  getSessionTitle(param: string = MenuOption.NcrOpened): void {
    const title = {
      [MenuOption.NcrOpened]: Title.NcrOpened,
      [MenuOption.NcrInAnalysis]: Title.NcrInAnalysis,
      [MenuOption.NcrWaitingForApproval]: Title.NcrWaitingForApproval,
      [MenuOption.NcrCompleted]: Title.NcrCompleted,
      [MenuOption.NcrCanceled]: Title.NcrCanceled,
    };
    this.title = title[param];
  }

  getCurrentStatus(param: string = MenuOption.NcrOpened): void {
    switch (param) {
      case 'opened':
        this.currentStatus = NonConformityReportStatus.Opened;
        break;
      case 'in-analysis':
        this.currentStatus = NonConformityReportStatus.InAnalysis;
        break;
      case 'waiting-for-approval':
        this.currentStatus = NonConformityReportStatus.WaitingApproval;
        break;
      case 'completed':
        this.currentStatus = NonConformityReportStatus.Completed;
        break;
      case 'canceled':
        this.currentStatus = NonConformityReportStatus.Cancelled;
        break;
      default:
        break;
    }
  }

  sortedBy = (field?: string) => {
    if (field !== this.sortBy) {
      this.ascSort = true;
    } else {
      this.ascSort = !this.ascSort;
    }
    this.sortBy = field;
    this.isLoadingList = true;
    this.ncrService
      .getNcrList(
        this.currentStatus,
        0,
        this.getElasticSearchFilter(this.filter.value),
        this.getSort()
      )
      .finally(() => {
        this.isLoadingList = false;
      });
  };

  onScroll = async () => {
    this.isLoadingScreen = true;
    this.ncrService
      .getNcrList(
        this.currentStatus,
        this.filteredReducedNonConformityReports.length,
        this.getElasticSearchFilter(this.filter.value),
        this.getSort()
      )
      .finally(() => {
        this.isLoadingScreen = false;
      });
  };

  private getElasticSearchFilter(value: any) {
    return new Filter(
      this.filteredFields,
      value,
      undefined,
      QueryName.simpleQueryString,
      true
    );
  }
}
