import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-title',
  templateUrl: './dynamic-title.component.html',
  styleUrls: ['./dynamic-title.component.scss'],
})
export class DynamicTitleComponent implements OnInit {
  arrayTitle: string[];

  @Input() title: string;
  @Input() subtitle: string;
  @Input() highlightedText: string;

  constructor() {}

  ngOnInit() {
    this.arrayTitle = this.title.split(' ');
  }
}
