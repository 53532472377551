import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-wash-reject',
  templateUrl: './wash-reject.component.html',
  styleUrls: ['./wash-reject.component.scss'],
})
export class WashRejectComponent implements OnInit, OnDestroy {
  rejectForm = this.fb.group({
    reason: ['', Validators.required],
  });
  actionsBarConfig: ActionsBarProperties;
  formStatusChangesSubscription: Subscription;

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<WashRejectComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public reasons: any
  ) {}

  ngOnDestroy(): void {
    this.formStatusChangesSubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.cancelDialog(),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
        },
        {
          btnClick: () => this.submitDialog(),
          btnId: 'reject',
          btnText: 'Reject',
          buttonStyle: ButtonStyle.PRIMARY,
          btnDisabled: !this.rejectForm.valid,
        },
      ],
    };
    this.formStatusChangesSubscription =
      this.rejectForm.statusChanges.subscribe((status) => {
        this.actionsBarConfig.buttons[1].btnDisabled = status !== 'VALID';
      });
  }

  cancelDialog = () => {
    this.dialogRef.close();
  };

  submitDialog = () => {
    if (this.rejectForm.valid) {
      const code = this.rejectForm.get('reason').value;
      const { displayName } = (this.reasons || []).find(
        (item) => item.code === code
      );
      this.dialogRef.close({ code, label: displayName });
    }
  };
}
