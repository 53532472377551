import { UserType } from '../../shared/navigation/user-type.enum';

export const allTiles = [
  {
    label: 'News',
    icon: 'full_coverage',
    link: '/dashboard/feed',
    customClasses: 'ontrax-feed',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Ordering',
    icon: 'local_shipping',
    link: '/dashboard/wash-list',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Scheduling',
    icon: 'calendar_month',
    link: '/dashboard/schedule',
    visibleByUserType: UserType.Internal,
    hidden: false,
    disabled: true,
    offlineDisabled: false,
  },
  {
    label: 'Shift Logs',
    icon: 'overview',
    link: '/dashboard/shift-logs',
    visibleByUserType: UserType.Internal,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'NCR',
    icon: 'problem',
    link: '/dashboard/non-conformity-report',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Reports',
    icon: 'insert_chart',
    link: '/dashboard/reports',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Heat Management',
    icon: 'device_thermostat',
    link: '/dashboard/heat-management',
    visibleByUserType: UserType.Internal,
    hidden: true,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Yard Check',
    icon: 'directions_walk',
    link: '/dashboard/yard-check',
    visibleByUserType: UserType.Internal,
    hidden: true,
    disabled: false,
    offlineDisabled: false,
  },
  {
    label: 'Bulk Upload',
    icon: 'cloud_upload',
    link: '/dashboard/bulk-upload/download-template',
    visibleByUserType: UserType.AllUserTypes,
    hidden: false,
    disabled: false,
    offlineDisabled: false,
  },
];
