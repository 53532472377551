import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../core/auth/auth.service';
import { NcrFormObject } from '../dashboard/non-conformity-report/ncr-master-form/ncr-master-form.model';
import { NcrService } from '../dashboard/non-conformity-report/ncr.service';
import { NonConformityReport } from '../dashboard/non-conformity-report/non-conformity-report.model';
import { PublicAPIService } from '../core/PublicAPI.service';
import { WarehouseService } from '../core/services/warehouse.service';
import { MasterAccountService } from '../core/services/master-account.service';

@Component({
  selector: 'app-external-ncr-creation',
  templateUrl: './external-ncr-creation.component.html',
  styleUrls: ['./external-ncr-creation.component.scss'],
})
export class ExternalNcrCreationComponent implements OnInit {
  options: Array<string> = ['Employee', 'Customer'];
  showForm = false;
  loadingSubmit = false;
  triedSubmit: boolean;

  isQualaWorker: boolean;
  identificationEmail: string;
  identificationName: string;

  newReport: NonConformityReport;

  identificationForm: UntypedFormGroup;
  employeeOrCustomer: UntypedFormControl;
  initiatorEmail: UntypedFormControl;
  initiatorName: UntypedFormControl;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private ncrService: NcrService,
    private warehouseService: WarehouseService,
    private masterAccountService: MasterAccountService
  ) {}

  ngOnInit(): void {
    const publicApi = new PublicAPIService();
    this.authService.clearUserData();
    this.createFormControls();
    this.createFormGroup();
    this.warehouseService.loadAllWarehouses(publicApi);
    this.masterAccountService.externalGetMasterCustomer().catch((error) => {
      throw error;
    });
  }

  createFormGroup() {
    this.identificationForm = new UntypedFormGroup({
      employeeOrCustomer: this.employeeOrCustomer,
      initiatorEmail: this.initiatorEmail,
      initiatorName: this.initiatorName,
    });
  }

  createFormControls() {
    this.employeeOrCustomer = new UntypedFormControl('', Validators.required);
    this.initiatorEmail = new UntypedFormControl(
      '',
      Validators.compose([Validators.required, Validators.email])
    );
    this.initiatorName = new UntypedFormControl('', Validators.required);
  }

  next() {
    const employeeOrCustomer = this.identificationForm.value.employeeOrCustomer;
    this.identificationEmail = this.identificationForm.value.initiatorEmail;
    this.identificationName = this.identificationForm.value.initiatorName;
    if (employeeOrCustomer === 'Employee') {
      this.isQualaWorker = true;
    } else {
      this.isQualaWorker = false;
    }
    this.showForm = true;
  }

  submit({ data, error }: { data: NcrFormObject; error: ValidationErrors }) {
    this.triedSubmit = true;

    if (!!error) {
      this.toastr.error('Error trying to submit the non conformity report.');
      throw error;
    }

    this.loadingSubmit = true;
    this.newReport = {
      madeByQualaWorker: this.isQualaWorker,
      createdBy: {
        name: this.identificationName,
        email: this.identificationEmail,
      },
      customer: {
        businessPartnerName: data.companyName,
        email: this.isQualaWorker
          ? data.customerEmail
          : this.identificationEmail,
        contactName: data.customerName,
        phone: data.phoneNumber,
        orderNumber: data.orderNumber,
      },
      problemReportedDateTime: {
        dateDiscovered: data.dateDiscovered,
        dateIssued: data.dateIssued,
      },
      qualaInformation: {
        terminalName: data.terminal,
        terminalNumber: data.terminalInfo.terminalNumber,
        workOrder: data.workOrder,
      },
      peopleInvolved: {
        cleaner: data.cleaner,
        facilityManagerName: data.facilityManager,
        shiftSupervisor: data.shiftSupervisor,
        technician: data.technician,
        namesOfPeopleInvolved:
          (data.namesOfPeopleInvolved &&
            data.namesOfPeopleInvolved.trim().split(',')) ||
          [],
        shiftStartTime: data.shiftStartTime,
      },
      tankInformation: {
        isFoodGrade: data.isFoodGradeClean,
        number: data.containerNumber,
        tankType: data.containerType,
        washType: data.typeOfWash,
      },
      issueDescription: data.issueDescription,
      issueType: data.issueType,
      equipmentOwner: data.equipmentOwner,
      damage: {
        hasBeenDamaged: data.wasEquipmentDamaged,
        whatWasDamaged: data.whatWasDamaged,
        howItGotDamaged: data.howWasDamaged,
        repairCost: data.repairCost,
        driverName: data.driverName,
        whoDidTheRepair: data.whoDidTheRepair,
        receipt: data.receipt,
        tractorNumber: data.tractorNumber,
        trailerNumber: data.trailerNumber,
      },
      wasAnyoneInjured: data.wasAnyoneInjured,
      shipperName: data.shipperName,
      whoGotHurt: data.whoGotHurt,
      whoHadSignedStatement: data.peopleStatement,
      whoHaveBeenNotified: data.peopleNotified,
      hasTheOwnerBeenNotified: data.hasTheOwnerBeenNotified,
      uploadedFiles: data.uploadedFiles,
    };

    if (data.companyId) {
      this.newReport.customerMasterId = data.companyId;
    }

    this.ncrService
      .createExternalNCR(this.newReport)
      .then(() => {
        this.toastr.success('', 'Non conformity report submitted!');
      })
      .catch(() => {
        this.toastr.error('Error trying to submit the non conformity report.');
      })
      .finally(() => {
        this.return();
        this.loadingSubmit = false;
      });
  }

  return() {
    this.createFormControls();
    this.createFormGroup();
    this.showForm = false;
    this.triedSubmit = false;
  }

  phoneLink(): void {
    window.open('tel:1-800-835-6252', '_blank');
  }

  emailLink(): void {
    window.open('mailto:info@depotconnect.com', '_blank');
  }

  addressLink(): void {
    window.open('https://maps.app.goo.gl/DvkjQKYSXJd41BtX7', '_blank');
  }
}
