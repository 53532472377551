import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { get } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { DeviceDetectorService } from 'ngx-device-detector';

import { AuthService } from '../core/auth/auth.service';
import {
  MenuConfig,
  MenuOption,
  OnTraxSubSystemPlatformsMenus,
} from '../shared/navigation/navigation.model';
import { WashListService } from './wash-list/wash-list.service';
import { APIService } from '../core/API.service';
import { SystemService } from '../core/system.service';
import { User } from '../core/model/user.model';
import { LocalStorageService } from '../core/localstorage.service';
import { BusinessPartnerService } from '../core/services/business-partner.service';
import { WarehouseService } from '../core/services/warehouse.service';
import { MasterAccountService } from '../core/services/master-account.service';
import { GtmUtilsService } from '../shared/gtm-utils.service';
import { requestListSection } from '../shared/navigation/navigation.config';
import { UserType } from '../shared/navigation/user-type.enum';
import { NavigationService } from '../shared/navigation/navigation.service';
import { FunctionRegistryService } from '../shared/function-registry/function-registry.service';
import { SideMenuToggle } from './dashboard.model';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('slideMenu', [
      state(
        'true',
        style({
          transform: 'none',
        })
      ),
      transition('true <=> false', animate('300ms ease-in-out')),
    ]),
  ],
})
export class DashboardComponent implements OnInit {
  static route = 'dashboard';
  subSystemName: string;
  imageFolderPath = '../../assets/images/';
  defaultLandscapeViewLogoPath = `${this.imageFolderPath}ontrax-logo-complete-v1.svg`;
  landscapeViewLogoPath: string;
  menuConfig: MenuConfig;
  isMobile: boolean;
  private user: User;
  userName: string;
  isDesktop: boolean;
  isPortrait: boolean;
  showMobileMenu: boolean; // #TODO remove feature flag after tiles version
  isScreenMobile: boolean;
  showSubnavMenu: boolean = true;
  sectionSelected: string;
  hasQualaRole: boolean;

  hasSubnavPermission: boolean = true;

  constructor(
    private businessPartnerService: BusinessPartnerService,
    private washListService: WashListService,
    private warehouseService: WarehouseService,
    private breakpointObserver: BreakpointObserver,
    private authService: AuthService,
    private appSyncService: APIService,
    private systemService: SystemService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
    private masterAccountService: MasterAccountService,
    private gtmUtilsService: GtmUtilsService,
    private menuService: NavigationService,
    private functionRegistryService: FunctionRegistryService,
    private deviceDetector: DeviceDetectorService,
    library: FaIconLibrary
  ) {
    library.addIconPacks(fas);
    this.authService.user$.subscribe((user) => {
      if (user) this.refetchDynamicContent();
    });
    this.breakpointObserver.observe(Breakpoints.Handset).subscribe((result) => {
      this.washListService.changeBreakpointFlag(!result.matches);
      this.isMobile = !result.matches;
    });

    this.breakpointObserver
      .observe([
        Breakpoints.WebPortrait,
        Breakpoints.HandsetPortrait,
        Breakpoints.Handset,
      ])
      .subscribe((result) => {
        this.isPortrait = result.matches;
      });

    this.landscapeViewLogoPath = this.defaultLandscapeViewLogoPath;
    this.isScreenMobile = this.deviceDetector.isMobile();
  }

  refetchDynamicContent(): void {
    if (this.localStorageService) {
      this.setUserData();
      this.getHasQualaRole();
      this.loadSchneiderCompletionOption();
      this.configureMenu();
      this.loadWashBillToTerminal();
    }
  }

  loadSchneiderCompletionOption() {
    if (this.authService.hasQualaWorkerRole()) {
      this.gtmUtilsService
        .getGtmConfig('showSchneiderPortalCompletions')
        .then((showSection) => {
          if (!showSection) {
            return;
          }

          this.menuConfig?.sections?.find((section) => {
            if (section.value === 'wash-list') {
              const schneiderOption = requestListSection.options.find(
                (option) =>
                  option.value === MenuOption.SchneiderPortalCompletions
              );
              if (
                schneiderOption &&
                this.authService.getUserType() === UserType.Internal
              ) {
                section.options.push(schneiderOption);
              }
            }
          });
        });
    }
  }

  ngOnInit() {
    this.listenToUrlChanges();
    this.isDesktop = !this.isPortrait && !this.isScreenMobile;

    this.showSubnavMenu = this.isScreenMobile && this.isPortrait ? false : true;
    this.businessPartnerService.loadBusinessPartners().catch((error) => {
      throw error;
    });
    this.setUserData();
    this.configureMenu();
    this.getHasQualaRole();
    this.loadSchneiderCompletionOption();
    this.functionRegistryService.setFunction(
      SideMenuToggle.SIDE_MENU,
      this.toggleSubnav.bind(this)
    );
    this.businessPartnerService.loadBusinessPartners().catch((error) => {
      throw error;
    });
    this.masterAccountService.getMasterCustomer().catch((error) => {
      throw error;
    });
    this.washListService.loadServiceType().catch((error) => {
      throw error;
    });
    this.washListService.loadContainerType().catch((error) => {
      throw error;
    });
    this.washListService.loadProductContent().catch((error) => {
      throw error;
    });
    this.warehouseService.loadActiveWarehouses().catch((error) => {
      throw error;
    });
    this.warehouseService.loadAllWarehouses().catch((error) => {
      throw error;
    });
    this.washListService.loadExteriorWashProducts().catch((error) => {
      throw error;
    });

    this.washListService.getReasonsOfChange().catch((error) => {
      throw error;
    });

    this.loadWashBillToTerminal();

    this.appSyncService.OnMaintenanceChangesListener.subscribe((event: any) => {
      const maintenance = get(
        event,
        ['value', 'data', 'onMaintenanceChanges'],
        {}
      );
      this.systemService.setMaintenanceStatus(maintenance.hasMaintenance);
      if (maintenance.hasMaintenance) {
        this.router.navigate(['/system-maintenance']);
      }
    });

    if (this.user.customerBlocking) {
      this.toastr.warning(
        '',
        'Account on hold. Requests can be entered but will not be scheduled. Please contact your sales representative.'
      );
    }
  }

  public toggleSubnav(type?: string): void {
    type === 'expand-subnav'
      ? (this.showSubnavMenu = true)
      : (this.showSubnavMenu = !this.showSubnavMenu);
  }

  public showMenu(): boolean {
    return this.showSubnavMenu || this.isDesktop;
  }

  public setSection(section: string): void {
    this.sectionSelected = section;
  }

  public setSubnavPermission(hasPermission: boolean) {
    this.hasSubnavPermission = hasPermission;
  }

  public getHasQualaRole() {
    this.hasQualaRole = this.authService.hasQualaWorkerRole();
  }

  public panelModeOff(): boolean {
    if (!this.isDesktop) {
      return true;
    }
    const menuDefaultFromUrl =
      this.sectionSelected == 'schedule' || !this.sectionSelected;
    const isScheduleRoute = this.router.url.includes('schedule');
    const panelMode = this.localStorageService.getPanelMode();

    if (panelMode && isScheduleRoute && menuDefaultFromUrl) {
      return false;
    } else {
      return true;
    }
  }

  listenToUrlChanges() {
    const subSystemInfos = [
      {
        urls: ['wash-list'],
        logoPath: `${this.imageFolderPath}ontrax-requests-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.Requests,
      },
      {
        urls: ['non-conformity-report'],
        logoPath: `${this.imageFolderPath}ontrax-ncr-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.Ncr,
      },
      {
        urls: [MenuOption.Schedule],
        logoPath: `${this.imageFolderPath}ontrax-scheduling-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.Scheduling,
      },
      {
        urls: [
          MenuOption.Reports,
          MenuOption.WashMetrics,
          MenuOption.LeanDailyManagementReport,
          MenuOption.TerminalScorecardDepot,
          MenuOption.TerminalScorecardIndustrial,
          MenuOption.TerminalScorecardRail,
          MenuOption.TerminalScorecardWash,
          MenuOption.EhsWasteManagement,
        ],
        logoPath: `${this.imageFolderPath}ontrax-reports-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.Reports,
      },
      {
        urls: [MenuOption.ShiftLogs],
        logoPath: `${this.imageFolderPath}ontrax-shift-logs-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.ShiftLogs,
      },
      {
        urls: ['bulk-upload'],
        logoPath: `${this.imageFolderPath}ontrax-bulk-upload-logo.svg`,
        name: OnTraxSubSystemPlatformsMenus.BulkUpload,
      },
    ];

    this.router.events.subscribe((event) => {
      for (const subSystem of subSystemInfos) {
        for (const url of subSystem.urls) {
          if (this.router.url.includes(url)) {
            this.landscapeViewLogoPath = subSystem.logoPath;
            this.subSystemName = subSystem.name;
            return;
          }
        }
      }
      this.landscapeViewLogoPath = this.defaultLandscapeViewLogoPath;
      this.subSystemName = OnTraxSubSystemPlatformsMenus.Default;
    });
  }

  private loadWashBillToTerminal(): void {
    const customerId = this.authService.getBusinessPartnerId();
    if (customerId) this.washListService.loadWashBillToTerminal(customerId);
  }

  private configureMenu(): void {
    this.menuConfig = this.menuService.getMenuConfig();
    this.gtmUtilsService
      .getGtmConfig('showMobileMenu')
      .then((showMobileMenu) => {
        this.showMobileMenu = showMobileMenu;
      });
  }

  private setUserData(): void {
    this.user = this.localStorageService.getUser();
    this.userName = this.user.username;
  }
}
