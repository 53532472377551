// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wash-reject-with-heel-component .form-container {
  width: 100%;
}
.wash-reject-with-heel-component .question-label {
  margin: 15px 0;
}
.wash-reject-with-heel-component .actions {
  display: flex;
  background-color: black;
  border-radius: 12px;
  padding: 8px;
  justify-content: flex-end;
  margin-top: 15px;
}
.wash-reject-with-heel-component .actions .action-button {
  width: initial;
}
.wash-reject-with-heel-component .actions .action-button:first-child {
  margin-right: 8px;
}
.wash-reject-with-heel-component .actions .disabled-btn {
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .wash-reject-component .actions {
    justify-content: center;
  }
  .wash-reject-component .actions .action-button {
    width: 100%;
  }
  .wash-reject-component .actions .action-button:first-child {
    margin-right: 8px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/wash-list/wash-reject-with-heel/wash-reject-with-heel.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAGE;EACE,cAAA;AADJ;AAIE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,gBAAA;AAFJ;AAII;EACE,cAAA;AAFN;AAKI;EACE,iBAAA;AAHN;AAMI;EACE,oBAAA;AAJN;;AASA;EAEI;IACE,uBAAA;EAPJ;EASI;IACE,WAAA;EAPN;EAUI;IACE,iBAAA;EARN;AACF","sourcesContent":[".wash-reject-with-heel-component {\n  .form-container {\n    width: 100%;\n  }\n\n  .question-label {\n    margin: 15px 0;\n  }\n\n  .actions {\n    display: flex;\n    background-color: black;\n    border-radius: 12px;\n    padding: 8px;\n    justify-content: flex-end;\n    margin-top: 15px;\n\n    .action-button {\n      width: initial;\n    }\n\n    .action-button:first-child {\n      margin-right: 8px;\n    }\n\n    .disabled-btn {\n      pointer-events: none;\n    }\n  }\n}\n\n@media only screen and (max-width: 600px) {\n  .wash-reject-component {\n    .actions {\n      justify-content: center;\n\n      .action-button {\n        width: 100%;\n      }\n\n      .action-button:first-child {\n        margin-right: 8px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
