import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ncr-cancel',
  templateUrl: './ncr-cancel.component.html',
  styleUrls: ['./ncr-cancel.component.scss'],
})
export class NcrCancelComponent implements OnInit, OnDestroy {
  cancelForm = this.fb.group({
    cancelReason: ['', Validators.required],
  });
  actionsBarConfig: ActionsBarProperties;
  formStatusChangesSubscription: Subscription;

  constructor(
    public fb: UntypedFormBuilder,
    public dialogRef: MatDialogRef<NcrCancelComponent>
  ) {
    this.initActionsBarConfigs();
  }

  ngOnInit(): void {
    this.initActionsBarConfigs();
  }

  ngOnDestroy(): void {
    this.formStatusChangesSubscription.unsubscribe();
  }

  initActionsBarConfigs() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnClick: () => this.cancelDialog(),
          btnId: 'abort',
          btnText: 'Abort',
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
        },
        {
          btnClick: () => this.submitDialog(),
          btnId: 'cancel',
          btnText: 'Cancel',
          icon: 'block',
          buttonStyle: ButtonStyle.PRIMARY,
          btnDisabled: !this.cancelForm.valid,
        },
      ],
    };
    this.formStatusChangesSubscription =
      this.cancelForm.statusChanges.subscribe((status) => {
        this.actionsBarConfig.buttons[1].btnDisabled = status !== 'VALID';
      });
  }

  cancelDialog = () => {
    this.dialogRef.close();
  };

  submitDialog = () => {
    if (this.cancelForm.valid) {
      const reason = this.cancelForm.get('cancelReason').value;
      this.dialogRef.close(reason);
    }
  };
}
