import { NgModule } from '@angular/core';

import { NonConformityReportRoutingModule } from './non-conformity-report-routing.module';
import { NcrListComponent } from './ncr-list/ncr-list.component';
import { NcrItemComponent } from './ncr-item/ncr-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NcrMasterFormComponent } from './ncr-master-form/ncr-master-form.component';
import { InternalNcrCreationComponent } from './internal-ncr-creation/internal-ncr-creation.component';
import { NcrCancelComponent } from './ncr-cancel/ncr-cancel.component';
import { NcrEditComponent } from './ncr-edit/ncr-edit.component';
import { InvestigationFormSectionComponent } from './ncr-master-form/investigation-form-section/investigation-form-section.component';
import { AssignOverlayComponent } from './assign-overlay/assign-overlay.component';
import { FiveWhyFormSectionComponent } from './ncr-master-form/five-why-form-section/five-why-form-section.component';
import { ReviewFormSectionComponent } from './ncr-master-form/review-form-section/review-form-section.component';
import { DynamicTableComponent } from '../../shared/dynamic-table/dynamic-table.component';
import { RowComponent } from 'src/app/shared/dynamic-table/row/row.component';

@NgModule({
  declarations: [
    NcrListComponent,
    NcrItemComponent,
    NcrMasterFormComponent,
    InternalNcrCreationComponent,
    NcrCancelComponent,
    NcrEditComponent,
    InvestigationFormSectionComponent,
    AssignOverlayComponent,
    FiveWhyFormSectionComponent,
    ReviewFormSectionComponent,
  ],
  imports: [
    SharedModule,
    NonConformityReportRoutingModule,
    DynamicTableComponent,
    RowComponent,
  ],
  exports: [NcrMasterFormComponent],
})
export class NonConformityReportModule {}
