// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-component {
  display: grid;
  grid-template-rows: auto auto;
  padding: 2rem 0rem;
}
.dashboard-component .header {
  padding-top: 24px;
  padding-bottom: 16px;
}
.dashboard-component .info-area {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.dashboard-component .info-area .loading-container {
  display: flex;
}

:host ::ng-deep app-ellipsis-loader .lds-ellipsis div {
  top: 16px !important;
}

#dashboard-container {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/dashboard/quicksight/quicksight-dashboard.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,6BAAA;EACA,kBAAA;AACF;AACE;EACE,iBAAA;EACA,oBAAA;AACJ;AAEE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEI;EACE,aAAA;AAAN;;AAOI;EACE,oBAAA;AAJN;;AASA;EACE,WAAA;AANF","sourcesContent":[".dashboard-component {\n  display: grid;\n  grid-template-rows: auto auto;\n  padding: 2rem 0rem;\n\n  .header {\n    padding-top: 24px;\n    padding-bottom: 16px;\n  }\n\n  .info-area {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n\n    .loading-container {\n      display: flex;\n    }\n  }\n}\n\n:host ::ng-deep {\n  app-ellipsis-loader {\n    .lds-ellipsis div {\n      top: 16px !important;\n    }\n  }\n}\n\n#dashboard-container {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
