import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { WashRequest } from '../wash-list.model';
import { WashListService } from '../wash-list.service';
import { ActivatedRoute } from '@angular/router';
import { MenuOption } from '../../../shared/navigation/navigation.model';
import { HeelActions } from '../wash-item/heel-actions';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/core/auth/auth.service';
import {
  ActionsBarProperties,
  ButtonStyle,
} from 'src/app/shared/actions-bar/actions-bar.model';

@Component({
  selector: 'app-wash-heel-management',
  templateUrl: './wash-heel-management.component.html',
  styleUrls: ['./wash-heel-management.component.scss'],
})
export class WashHeelManagementComponent implements OnInit {
  currentMenuOption: MenuOption;
  showHeelForm: boolean;
  heelForm: UntypedFormGroup;
  comment: UntypedFormControl;
  isVisible: boolean;
  isAccept: boolean;
  headerText: string;
  commentLabel: string;
  commentRequired: boolean;

  validationMessage: string;
  displayValidationMessage = false;
  isProcessing: boolean;
  hasDispatcherRole = this.authService.hasDispatcherRole();
  actionsBarConfig: ActionsBarProperties;

  @Input() item: WashRequest;
  @Input() action: string;
  @Output() closed: EventEmitter<any> = new EventEmitter();

  constructor(
    private washListService: WashListService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.currentMenuOption = this?.route?.params['option'];
    this.route.queryParams.subscribe((queryParams) => {
      this.currentMenuOption = queryParams.option;
    });
    this.showHeelForm = true;
    this.createFormControls();
    this.createFormGroup();
    this.washListService.currentBreakpoint.subscribe(
      (flag) => (this.isVisible = flag)
    );

    switch (this.action) {
      case HeelActions.ACCEPT: {
        this.headerText = 'Heel and hand labor approval';
        this.commentLabel = 'PO Number';
        this.isAccept = true;
        break;
      }
      case HeelActions.DENY: {
        this.headerText = 'Deny hand labor';
        this.commentLabel = 'Reason for denial*';
        this.heelForm.get('comment').setValidators([Validators.required]);
        break;
      }
      case HeelActions.REQUEST_INFO: {
        this.headerText = 'Request more information';
        this.commentLabel = 'Additional information*';
        this.heelForm.get('comment').setValidators([Validators.required]);
        break;
      }
      case HeelActions.PROVIDE_INFO: {
        this.headerText = 'Provide more information';
        this.commentLabel = 'Additional information*';
        this.heelForm.get('comment').setValidators([Validators.required]);
        break;
      }
    }
    this.configureActionsBar();
  }

  configureActionsBar() {
    this.actionsBarConfig = {
      centralizeComponents: false,
      buttons: [
        {
          btnText: 'Cancel',
          btnId: 'edit-cancel',
          btnClick: () => this.cancel(),
          icon: 'close',
          buttonStyle: ButtonStyle.SECONDARY,
        },
        {
          btnText: 'Save',
          btnId: 'edit-save',
          btnClick: () => this.submit(),
          icon: 'check',
          buttonStyle: ButtonStyle.PRIMARY,
          isProcessing: this.isProcessing,
        },
      ],
    };
  }

  createFormControls() {
    this.comment = new UntypedFormControl('');
  }

  createFormGroup() {
    this.heelForm = new UntypedFormGroup({
      comment: this.comment,
    });
  }

  approveHeel(comment: string) {
    this.isProcessing = true;
    this.washListService.approveHeel(this.item.id, comment).then(
      (_) => {
        this.toastr.success('', 'Exception approved!');
        this.washListService
          .loadByOption(this.currentMenuOption)
          .catch((error) => {
            throw error;
          });
      },
      (error) => {
        this.validationMessage = 'Error trying to approve heel';
        this.displayValidationMessage = true;
        this.isProcessing = false;
        throw error;
      }
    );
  }

  denyHeel(comment: string) {
    this.isProcessing = true;
    this.washListService.denyHeel(this.item.id, comment).then(
      (_) => {
        this.toastr.success('', 'Exception denied!');
        this.washListService
          .loadByOption(this.currentMenuOption)
          .catch((error) => {
            throw error;
          });
      },
      (error) => {
        this.validationMessage = 'Error trying to deny heel';
        this.displayValidationMessage = true;
        this.isProcessing = false;
        throw error;
      }
    );
  }

  requestInfo(comment: string) {
    this.isProcessing = true;
    this.currentMenuOption = this.route.params['option'];
    this.washListService.requestInfo(this.item.id, comment).then(
      (_) => {
        this.toastr.success('', 'Message has been sent!');
        this.washListService
          .loadByOption(this.currentMenuOption)
          .catch((error) => {
            throw error;
          });
      },
      (error) => {
        this.validationMessage = 'Error trying to request information';
        this.displayValidationMessage = true;
        this.isProcessing = false;
        throw error;
      }
    );
  }

  provideMoreInfo(comment: string, files: Array<any>) {
    this.isProcessing = true;
    this.washListService.provideInfo(this.item.id, comment, files).then(
      (_) => {
        this.toastr.success('', 'Message has been sent!');
        this.washListService
          .loadByOption(this.currentMenuOption)
          .catch((error) => {
            throw error;
          });
      },
      (error) => {
        this.validationMessage = 'Error trying to provide information';
        this.displayValidationMessage = true;
        this.isProcessing = false;
        throw error;
      }
    );
  }

  submit = () => {
    this.isProcessing = true;
    this.configureActionsBar();

    if (!this.heelForm.valid) {
      this.validationMessage = `Please enter the ${this.commentLabel}`;
      this.displayValidationMessage = true;
      this.isProcessing = false;
      this.configureActionsBar();
      return;
    }

    const comment = this.heelForm.get('comment').value;

    switch (this.action) {
      case HeelActions.ACCEPT: {
        this.approveHeel(comment);
        break;
      }
      case HeelActions.DENY: {
        this.denyHeel(comment);
        break;
      }
      case HeelActions.REQUEST_INFO: {
        this.requestInfo(comment);
        break;
      }
      case HeelActions.PROVIDE_INFO: {
        this.provideMoreInfo(comment, this.item.files);
        break;
      }
    }
    this.configureActionsBar();
    this.closed.emit();
  };

  cancel = () => {
    this.closed.emit();
  };
}
